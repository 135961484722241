export const head = {
  home: "Home",
  team: "Team",
  case: "Case",
  industry: "Industry",
  contact: "Contact",
  about: "About"
};
  
export const foot = {
  services: {
      title: "Services",
      home: "Home"
  },
  case: {
      title: "Case",
      case: "Case"
  },
  company: {
      title: "Company",
      contact: "Contact",
      about: "About"
  },
  resources: {
      title: "Resources",
      industry: "Indeustry"
  },
  stay: {
      title: "Stay With Us.",
      desc: "2000+ Our clients are subscribe Around the World"
  },
  copyright: {
      contact: "Contact Us"
  }
};

export const home = {
  banner22: {
    title: "Happy New Year 2024",
    desc: "The Knight Consortium spends a good time with you: increasing wealth and full of happiness"
  },
  banner1: {
      title: "KNight Consortium",
      desc: "From retail investors to bankers, the leader of KNight Consortium"
  },
  banner2: {
      title: "Our Commitment",
      desc: "Fair Competition in the Financial Sector"
  },
  banner3: {
      title: "Gentlemen love fortune, in a proper way",
      desc: "The Knight Consortium is willing to guide this broad road to more people to discover, so that everyone can achieve initial prosperity and prosperity, and then achieve self-realization!"
  },
  servicefive: {
      title: "Progress, development, and care are the core values of KNight Consortium",
    //   desc: "We are committed to promoting global economic relations and economic development. By integrating global knowledge, technology, funds and talents, we have established overseas service teams and provided funds to help areas lacking in agriculture, industry, economic construction, medical care and education around the world enjoy the same development opportunities. , work together to create a future vision of equality, dignity, prosperity and health.",
      desc: "",
      item1: {
          title: "Company Profile",
        //   desc: "KNight Consortium was established in Singapore in 2010 with an initial capital of approximately US$5 million, and now has a managed capital of US$1 billion.",
        desc: "KNight Consortium was established in Singapore in 2010 with an initial capital of approximately US$5 million, and now has a managed capital of US$1 billion.We are committed to promoting global economic relations and economic development, and establishing overseas service teams by integrating global knowledge, technology, funds and talents."
      },
      item2: {
          title: "Company Culture",
          desc: "The founding purpose of KNight Consortium is to gather love, convey warmth, help people participate in charity, and share a better life and development opportunities together. Promote connections around the world and provide assistance to refugees in countries and regions affected by natural disasters.<br><br><br>",
      },
      item3: {
          title: "Company Vision",
          desc: "The goals and important connotations of developing affairs with international cooperation and sustainable development are the vision of the organization. In this direction, we work with the international community, civil society and other development aid to actively integrate knowledge, technology, funds and talents to create a future of equality, dignity, prosperity and health.",
      }
  },
  portfolio: {
      title: "KNight Consortium investment industry and successful cases",
      desc: "We strive to promote global economic and social development and economic relations, and establish overseas service teams to assist the development of global medical, agricultural, industrial, economic construction, education, etc.",
      cat1: {
          name: "finance",
          item1: {
              title: "KNight Consortium investment team bought shares in a number of precious metals mining companies. These companies performed well during 2012 as rising precious metal prices caused their share prices to rise, resulting in huge gains for the KNights syndicate.",
          },
          item2: {
              title: "During the period from January to February 2012, the international gold price fluctuated. Financial analysts from KNight Consortium seized the opportunity to buy at low prices and made nearly 60% profit in just three months.",
          }
      },
      cat2: {
          name: "medical",
          item1: {
              title: "In 2020, the KNight Consortium  Medical Service Center was established, and the \"tumor immunotherapy\" treatment method jointly developed with Merck Pharmaceutical Group was successful.",
          },
          item2: {
              title: "KNight Consortium Medical Services Center and Novartis Pharmaceuticals have developed the cancer targeted drug Houng-592",
          }
      },
      cat3: {
          name: "metaverse",
          item1: {
              title: "In 2021, the KNight Consortium  web3.0 center was established to create a decentralized social platform, which attracted widespread attention and has been well received by users.",
          },
          item2: {
              title: "In the bull market of cryptocurrencies in January 2017, the KNight Consortium seized the opportunity and led the foundation members to achieve high profits, and the assets of all members quickly doubled.",
          }
      }
  },
  counterdata: {
    title: "Current status and future plans of KNight Consortium",
    desc: "",
    item1: {
        title: 'Funds',
        unit: "Billion",
    },
    item2: {
        title: 'Users',
        unit: "Billion",
    },
    item3: {
        title: 'Invset',
        unit: "Partner",
    },
    item4: {
        title: 'Donate',
        unit: "Billion",
    }
  },
  leader: {
      title: "Introduction to the members of the KNight Consortium Alliance",
      desc: "Members of the syndicate capital invest together, do charity, achieve financial freedom and contribute to a better world.",
      item1: {
          name: "Zhang Guofu",
          desc: "Obtained a master's degree in financial economics from the University of Oxford, UK. In August 2010, he founded the KNight Consortium Capital Foundation and served as the chairman of Consortium Capital."
      },
      item2: {
          name: "Remus John Worm",
          desc: "Master of Economics from the University of Chicago School of Economics. Served as Vice Chairman of KNight Capital Foundation <br><br><br> "
      },
      item3: {
          name: "Li Rongcheng",
          desc: "Master of Science and Master of Business Administration from the University of Ottawa. Served as Vice Chairman of KNight Capital Foundation<br><br>"
      }
  },
  blogpost: {
    title: "KNight Consortium Social Welfare",
    desc: "Wealth will definitely bring flowing hope and love. The KNight Consortium follows the example of foreign financial tycoons and uses part of the foundation's income for charity to relieve the siege and help development, making the KNight Consortium win international acclaim and the trust of more users and investors trust.",
    item1: {
        // title: "After the 7.8-magnitude earthquake in Turkey in February 2023, the KNight Consortium donated US$2.3 million to refugees"
        title: 'Donating materials is our most direct and practical help to the victims. In February 2023, Turkey suffered a 7.8-magnitude earthquake, and the Knight Consortium donated US$2.3 million to refugees to rebuild their homes.',
    },
    item2: {
        // title: "In July 2021, heavy rains caused floods in Zhengzhou, Henan Province, China. The KNight Consortium donated US$1.8 million;"
        title: 'In September 2022, the Knight Foundation charity team went to Africa to provide care and help to poor children and ensure that the children\'s basic living needs are met. The peer-to-peer funding method can more directly improve the lives of children and give them hope and encouragement.',
    },
    item3: {
        // title: "During the 2019 Australian forest fires, Consortium Capital donated US$1.2 million for relief supplies and recovery."
        title: 'In February 2023, Turkey suffered a 7.8-magnitude earthquake, many areas faced severe damage and casualties. The Knight Foundation sent rescue teams to rescue the victims, give them warmth and hope, and let them feel the warmth and care of the social family.',
    },
    item4: {
      title: 'Civilians did not receive any protection during the Israeli-Palestinian war. The Knights Consortium ensured that the people received basic assistance and actively provided important aid workers.',
    },
    item5: {
      title: 'In the Palestinian-Israeli war, the opening of supply lines and humanitarian assistance are critical and urgent needs. The Knights Consortium seizes the window period to deliver aid supplies that are crucial to the well-being of the Gaza people, and coordinates with international humanitarian organizations to ensure the arrival of aid supplies.',
    },
    item6: {
      title: 'As the Israeli-Palestinian war continues, the number of casualties continues to increase. The rescue of the victims is imminent, and the Knight Consortium actively organizes rescue teams to provide immediate rescue.',
    },
    item7: {
        title: 'The Knight Consortium has always been known for its professional assistance. We have an experienced team that can respond quickly and provide practical solutions. We all operate in an efficient and transparent manner to ensure that every bit of help is used appropriately and effectively.',
    },
    item8: {
        title: 'Choosing the professional assistance of Knight Consortium means you have chosen a reliable partner, a team dedicated to improving lives and rebuilding hope. We will spare no effort to provide the victims with the support they need to help them tide over the difficulties and move towards a better future.',
    },
    item9: {
        title: 'In the haze of war, post-war reconstruction is a critical step we must take. Rebuilding communities, repairing damaged buildings, and reintegrating victims into society require professional assistance and committed support.',
    },
    item10: {
        title: 'The Knight Consortium immediately came to the rescue and provided a ray of aid to the victims of the Palestinian-Israeli war. In the face of the challenges of the Israeli-Palestinian war, our Knight Consortium stands firmly by the victims. As a team committed to financial equity and social responsibility, we have always considered care and assistance to be one of our responsibilities.',
    },
    item11: {
        title: 'In the Palestinian-Israeli war, we are well aware of the challenges and difficulties faced by the victims. We have taken emergency action to provide urgently needed material assistance to the affected areas. These supplies include food, water, medical supplies and emergency rescue equipment and are designed to provide much-needed help and support to those affected by the disaster.',
    },
    item12: {
        title: 'The Knight Consortium has long been committed to supporting post-war revitalization. Rebuilding communities and helping those affected is crucial. Therefore, we do not hesitate to reach out to ensure that those affected by war can rebuild their lives.',
    },
    item13: {
      title: 'In the haze of war, we joined hands to provide aid to the victims. The Knight Consortium has always upheld a sense of social responsibility and is committed to helping those affected by the war. Let’s light up the lives of war victims with the light of hope!',
    },
    item14: {
      title: 'In the difficult moments after the war, the Knights Consortium is willing to be your reliable partner and join hands in the mission of rescue and reconstruction. We know that every disaster victim needs care and assistance, so we actively participate in various relief operations to provide necessary support to those suffering.',
    },
    item15: {
      title: 'The Knight Consortium has been committed to spreading this spirit. Through charitable actions, aid projects, and volunteer work, we strive to improve lives, support communities, and make the world a warmer place. Only by working together can we create a better and more hopeful future.',
    },
    item16: {
        title: 'Argentina was hit by a super strong storm, resulting in the loss of 16 innocent lives. The Knight Consortium was deeply saddened and responded immediately, and our professional team immediately participated in the disaster relief work. During this emergency, we remain committed to supporting the affected areas.',
    },
    item17: {
        title: 'We learned that a magnitude 6.2 earthquake occurred in Gansu, China. This disaster caused great distress to local residents. At this difficult time, the Knights Foundation stands on the side of the people in the disaster area and extends its deepest condolences and support.',
    },
    item18: {
        title: 'There are moments in life’s journey that require more care and support. The Knight Foundation takes social responsibility as its own responsibility. We firmly believe that helping those in need is an important part of building a better society, and we will always be by your side.',
    },
    item19: {
        title: 'The Knight Consortium is about to set off again! Looking at the neatly arranged milk, oil, noodles, biscuits and other food on the ground, they were about to deliver it to a local family in need. Seeing their happy faces, we know how much warmth and hope these supplies will bring. A love, a warmth, the Knight Foundation interprets social responsibility with actions, let us look forward to the touch brought by this delivery.',
    },
    item20: {
        title: 'Recently, we have witnessed a large team of knight consortium members, full of energy and passion, carrying loads of food to local communities to visit women, the elderly and children. Every member of this team exudes boundless enthusiasm and care, and their presence injects a warm current into the community. During the event, we saw young men and women interacting cordially with the elderly and children. Their smiles were filled with happiness and touch. Whether it is distributing food or chatting, everyone is involved and brings warmth and care to others with heart. This dynamic team of Knight Financial Group demonstrates the spirit of unity and friendship and injects a strong love into the community.',
    },
    item21: {
        title: 'Recently, a group of members of the Knight Consortium conducted a touching visit. They went to the local area and visited women, the elderly and children, bringing warmth and care. This event is not just a simple visit, but also a reflection of sincere care and support for the community. During the event, members of the Knights Foundation had cordial conversations with the interviewees and shared each other’s stories and experiences. The old people were smiling, the children were laughing, and the women were grateful. They listened attentively and accompanied with love, making every interviewee feel the warmth and care from the community.',
    },
    item22: {
        title: 'On a sunny day, the San Juan team of the Knight Consortium visited local families in need with full care. Team members distributed daily necessities such as rice, cooking oil, hollow noodles, cans, bread, and biscuits, and conducted cordial condolences and conversations with each family. This time\'s material distribution is not only practical support for daily needs, but also a spiritual comfort for every family. Knight volunteers share warm moments with the families being helped, conveying care and hope to each other. The families who were helped expressed their gratitude to the Knight volunteers. This warmth made them full of confidence and expectations for the future. When the volunteers left, the families sent their sincere blessings and thanked the San Juan team of the Knights Foundation for the care and warmth they brought to them.',
    },
    item23: {
        title: 'Members of the Knights Consortium extended a helping hand to several local poor families and sent them abundant daily necessities! In a past event, enthusiastic volunteers from the Knights Foundation went to the local area carrying abundant supplies such as cooking oil, noodles, wheat, and flour, and delivered these daily necessities to several poor families. This is not only a simple donation, but also a sincere condolences and communication. While receiving this warmth, the families being helped expressed their deep gratitude to the Knight volunteers. Difficulties in life seem to become warmer and full of hope and expectation because of this care.',
    },
    item24: {
        title: 'The volunteers of the Knight Consortium embarked on a touching charity journey with daily necessities such as bread, oil, milk, sugar, etc. They went deep into the mountainous areas and provided tangible help to those families in need who lived on the hillsides and had limited transportation. This material distribution covers all aspects of family daily life, including cooking oil, flour, milk, sugar, etc., ensuring that every family can feel the warm care of the Knights Foundation. Volunteers are not only carriers of supplies, but also deliverers of companionship, condolences and communication. The families being helped are located in remote mountainous areas and often face challenges such as inconvenient transportation and difficult living conditions. Therefore, the arrival of the Knight volunteers was a timely blessing for them and filled them with gratitude. Everyone shared each other\'s stories during the conversation, making this charity event more warm and meaningful.',
    },
    item25: {
      title: 'Recently, the sun was shining brightly, and members of the Knight Consortium came to the local area with buckets of cooking oil and juice to lend a helping hand and send warmth and care to those in need of support and help. On this beautiful day, we are not only members of the Knights Consortium, but also part of the community. We know that as a team, our responsibilities and missions are our shoulders. Therefore, we are willing to work hard and use actions to demonstrate our care and support for the community. Let us spread our love together and fill the world with warmth and hope. Thank you for your support, let us continue to move forward together and contribute to the development and progress of the community!',
    },
    item26: {
      title: 'Recently, our team from the Knight Consortium came to Cordoba, Argentina. This beautiful place is full of warmth and hope. Our team of nearly 30 members is gathered here. With a warm heart and your love and support, we will help more people in need and bring them warmth and care. In this action full of love, we are not only a member of the Knights Consortium, but also your spokesperson and messenger. Because of your generosity and love, we are able to get here and bring gratitude to more people. Let us join hands to contribute to society and fill the world with more warmth and hope. Thank you for your support, let us continue to spread the power of love!',
    }

  }
}

export const team = {
    leader1: {
        title: "Chairman",
        name: "Zhang Guofu",
        desc: "Master's degree in Financial Economics from the University of Oxford, UK"
    },
    leader2: {
        title: "Vice Chairman and Legal Director",
        item1: {
            name: "Remus John Worm",
            desc: "Master of Economics, University of Chicago School of Economics"
        },
        item2: {
            name: "Li Rongcheng",
            desc: "Master of Science and Master of Business Administration, University of Ottawa"
        },
        item3: {
            name: "Liu Caijun",
            desc: "Graduated from Peking University, Master of Management"
        }
    },
    leader3: {
        title: "Financial Analyst",
        item1: {
            name: "Maxwell",
            desc: "Bachelor of International Finance, Fudan University"
        },
        item2: {
            name: "Yang Jun",
            desc: "Graduated from Nanyang Technological University, Singapore, majoring in Finance"
        },
        item3: {
            name: "Hubert",
            desc: "Double degree from University of London, UK, and Harvard University, USA"
        }
    },
    leader4: {
        title: "Consultant",
        item1: {
            name: "Анфия",
            desc: "Bachelor's degree, University of Florida"
        },
        item2: {
            name: "Xu Hao",
            desc: "Engineering Degree from The George Washington University"
        },
        item3: {
            name: "Dorothy",
            desc: "MBA from Indian Institute of Technology"
        }
    },
    leader5: {
        title: "Core member",
        item1: {
            name: "sun horse",
            desc: "Studying Economics at Royal Holloway, University of London, UK"
        },
        item2: {
            name: "Yvonne",
            desc: "Master's degree from the Free University of Brussels"
        },
        item3: {
            name: "Yan Tao",
            desc: "Master's Degree from the University of Washington (Engineering Management Degree)"
        }
    }
}

export const casepage = {
    title: "Introduction to social welfare",
    item1: {
        title: "After the 7.8-magnitude earthquake in Turkey in February 2023, the KNight Consortium donated US$2.3 million to refugees"
    },
    item2: {
        title: "In July 2021, heavy rains caused floods in Zhengzhou, Henan Province, China. The KNight Consortium donated US$1.8 million;"
    },
    item3: {
        title: "During the 2019 Australian forest fires, Consortium Capital donated US$1.2 million for relief supplies and recovery."
    },
    item4: {
        title: "In February 2023, a 7.8-magnitude earthquake struck Turkey, causing more than 41,000 deaths. 1.5 million people were made homeless in the southern region, and at least 500,000 new houses needed to be built. In northwestern Syria, as many as 9 million people have been affected and at least 6,000 people have lost their lives. Consortium Capital donated US$2.3 million to refugees to provide living materials and rebuild their homes.",
    },
    item5: {
        title: "In June 2022, the earthquake in Afghanistan killed more than 1,000 people and injured more than 2,000 people. The consortium capital donated US$1 million for post-disaster housing reconstruction, part of which was used for daily necessities.",
    },
    item6: {
        title: "Donating materials is our most direct and practical help to the victims. In February 2023, Turkey suffered a 7.8-magnitude earthquake, and the Knight Consortium donated US$2.3 million to refugees to rebuild their homes.",
    },
    item7: {
        title: "In February 2023, Turkey suffered a 7.8-magnitude earthquake, many areas faced severe damage and casualties. The Knight Foundation sent rescue teams to rescue the victims, give them warmth and hope, and let them feel the warmth and care of the social family.",
    },
    item8: {
        title: "The earthquake in Afghanistan in June 2022 killed more than 1,000 people and injured more than 2,000 people. The consortium capital donated US$1 million for post-disaster housing reconstruction, part of which was used for living expenses.",
    },
    item9: {
        title: "The Knight Consortium actively participates in charity actions that care for poor children. Regular financial assistance and care are provided through donations and volunteer services. This kind of care and help not only changes the fate of children, but also injects more positive energy into the development of the entire society.",
    },
    item10: {
        title: "In September 2022, the Knight Foundation charity team went to Africa to provide care and help to poor children and ensure that the children's basic living needs are met. The peer-to-peer funding method can more directly improve the lives of children and give them hope and encouragement.",
    },
    item11: {
        title: "Civilians did not receive any protection during the Israeli-Palestinian war. The Knights Consortium ensured that the people received basic assistance and actively provided important aid workers.",
    },
    item12: {
        title: "In the Palestinian-Israeli war, the opening of supply lines and humanitarian assistance are critical and urgent needs. The Knights Consortium seizes the window period to deliver aid supplies that are crucial to the well-being of the Gaza people, and coordinates with international humanitarian organizations to ensure the arrival of aid supplies.",
    },
    item13: {
        title: "As the Israeli-Palestinian war continues, the number of casualties continues to increase. The rescue of the victims is imminent, and the Knight Consortium actively organizes rescue teams to provide immediate rescue.",
    },
    item14: {
        title: "In July 2023, affected by Typhoon No. 5 \"Dusuri\", extremely heavy rainfall occurred in Beijing, Tianjin, Hebei and other places in China, causing flood disasters. The Knight Consortium launched emergency rescue and provided timely rescue channels to help the elderly and people in distress get out of trouble.",
    },
    item15: {
        title: 'The Knight Consortium immediately came to the rescue and provided a ray of aid to the victims of the Palestinian-Israeli war. In the face of the challenges of the Israeli-Palestinian war, our Knight Consortium stands firmly by the victims. As a team committed to financial equity and social responsibility, we have always considered care and assistance to be one of our responsibilities.',
    },
    item16: {
        title: 'In the Palestinian-Israeli war, we are well aware of the challenges and difficulties faced by the victims. We have taken emergency action to provide urgently needed material assistance to the affected areas. These supplies include food, water, medical supplies and emergency rescue equipment and are designed to provide much-needed help and support to those affected by the disaster.',
    },
    item17: {
        title: 'The Knight Consortium has long been committed to supporting post-war revitalization. Rebuilding communities and helping those affected is crucial. Therefore, we do not hesitate to reach out to ensure that those affected by war can rebuild their lives.',
    },
    item18: {
        title: 'The Knight Consortium has always been known for its professional assistance. We have an experienced team that can respond quickly and provide practical solutions. We all operate in an efficient and transparent manner to ensure that every bit of help is used appropriately and effectively.',
    },
    item19: {
        title: 'Choosing the professional assistance of Knight Consortium means you have chosen a reliable partner, a team dedicated to improving lives and rebuilding hope. We will spare no effort to provide the victims with the support they need to help them tide over the difficulties and move towards a better future.',
    },
    item20: {
        title: 'In the haze of war, post-war reconstruction is a critical step we must take. Rebuilding communities, repairing damaged buildings, and reintegrating victims into society require professional assistance and committed support.',
    },
    item21: {
      title: 'In the haze of war, we joined hands to provide aid to the victims. The Knight Consortium has always upheld a sense of social responsibility and is committed to helping those affected by the war. Let’s light up the lives of war victims with the light of hope!',
    },
    item22: {
      title: 'In the difficult moments after the war, the Knights Consortium is willing to be your reliable partner and join hands in the mission of rescue and reconstruction. We know that every disaster victim needs care and assistance, so we actively participate in various relief operations to provide necessary support to those suffering.',
    },
    item23: {
      title: 'The Knight Consortium has been committed to spreading this spirit. Through charitable actions, aid projects, and volunteer work, we strive to improve lives, support communities, and make the world a warmer place. Only by working together can we create a better and more hopeful future.',
    },
    item24: {
        title: 'Argentina was hit by a super strong storm, resulting in the loss of 16 innocent lives. The Knight Consortium was deeply saddened and responded immediately, and our professional team immediately participated in the disaster relief work. During this emergency, we remain committed to supporting the affected areas.',
    },
    item25: {
        title: 'We learned that a magnitude 6.2 earthquake occurred in Gansu, China. This disaster caused great distress to local residents. At this difficult time, the Knights Foundation stands on the side of the people in the disaster area and extends its deepest condolences and support.',
    },
    item26: {
        title: 'There are moments in life’s journey that require more care and support. The Knight Foundation takes social responsibility as its own responsibility. We firmly believe that helping those in need is an important part of building a better society, and we will always be by your side.',
    },
    item27: {
        title: 'The Knight Consortium is about to set off again! Looking at the neatly arranged milk, oil, noodles, biscuits and other food on the ground, they were about to deliver it to a local family in need. Seeing their happy faces, we know how much warmth and hope these supplies will bring. A love, a warmth, the Knight Foundation interprets social responsibility with actions, let us look forward to the touch brought by this delivery.',
    },
    item28: {
        title: 'Recently, we have witnessed a large team of knight consortium members, full of energy and passion, carrying loads of food to local communities to visit women, the elderly and children. Every member of this team exudes boundless enthusiasm and care, and their presence injects a warm current into the community. During the event, we saw young men and women interacting cordially with the elderly and children. Their smiles were filled with happiness and touch. Whether it is distributing food or chatting, everyone is involved and brings warmth and care to others with heart. This dynamic team of Knight Financial Group demonstrates the spirit of unity and friendship and injects a strong love into the community.',
    },
    item29: {
        title: 'Recently, a group of members of the Knight Consortium conducted a touching visit. They went to the local area and visited women, the elderly and children, bringing warmth and care. This event is not just a simple visit, but also a reflection of sincere care and support for the community. During the event, members of the Knights Foundation had cordial conversations with the interviewees and shared each other’s stories and experiences. The old people were smiling, the children were laughing, and the women were grateful. They listened attentively and accompanied with love, making every interviewee feel the warmth and care from the community.',
    },
    item30: {
        title: 'On a sunny day, the San Juan team of the Knight Consortium visited local families in need with full care. Team members distributed daily necessities such as rice, cooking oil, hollow noodles, cans, bread, and biscuits, and conducted cordial condolences and conversations with each family. This time\'s material distribution is not only practical support for daily needs, but also a spiritual comfort for every family. Knight volunteers share warm moments with the families being helped, conveying care and hope to each other. The families who were helped expressed their gratitude to the Knight volunteers. This warmth made them full of confidence and expectations for the future. When the volunteers left, the families sent their sincere blessings and thanked the San Juan team of the Knights Foundation for the care and warmth they brought to them.',
    },
    item31: {
        title: 'Members of the Knights Foundation extended a helping hand to several local poor families and sent them abundant daily necessities! In a past event, enthusiastic volunteers from the Knights Foundation went to the local area carrying abundant supplies such as cooking oil, noodles, wheat, and flour, and delivered these daily necessities to several poor families. This is not only a simple donation, but also a sincere condolences and communication. While receiving this warmth, the families being helped expressed their deep gratitude to the Knight volunteers. Difficulties in life seem to become warmer and full of hope and expectation because of this care.',
    },
    item32: {
        title: 'The volunteers of the Knight Foundation embarked on a touching charity journey with daily necessities such as bread, oil, milk, sugar, etc. They went deep into the mountainous areas and provided tangible help to those families in need who lived on the hillsides and had limited transportation. This material distribution covers all aspects of family daily life, including cooking oil, flour, milk, sugar, etc., ensuring that every family can feel the warm care of the Knights Foundation. Volunteers are not only carriers of supplies, but also deliverers of companionship, condolences and communication. The families being helped are located in remote mountainous areas and often face challenges such as inconvenient transportation and difficult living conditions. Therefore, the arrival of the Knight volunteers was a timely blessing for them and filled them with gratitude. Everyone shared each other\'s stories during the conversation, making this charity event more warm and meaningful.',
    },
    item33: {
      title: 'Recently, the sun was shining brightly, and members of the Knight Consortium came to the local area with buckets of cooking oil and juice to lend a helping hand and send warmth and care to those in need of support and help. On this beautiful day, we are not only members of the Knights Consortium, but also part of the community. We know that as a team, our responsibilities and missions are our shoulders. Therefore, we are willing to work hard and use actions to demonstrate our care and support for the community. Let us spread our love together and fill the world with warmth and hope. Thank you for your support, let us continue to move forward together and contribute to the development and progress of the community!',
    },
    item34: {
      title: 'Recently, our team from the Knight Consortium came to Cordoba, Argentina. This beautiful place is full of warmth and hope. Our team of nearly 30 members is gathered here. With a warm heart and your love and support, we will help more people in need and bring them warmth and care. In this action full of love, we are not only a member of the Knights Consortium, but also your spokesperson and messenger. Because of your generosity and love, we are able to get here and bring gratitude to more people. Let us join hands to contribute to society and fill the world with more warmth and hope. Thank you for your support, let us continue to spread the power of love!',
    },
    item35: {
      title: 'Members of the Knights consortium gathered offline to share the feast of the America\'s Cup and European Cup finals! We not only enjoyed delicious food at the table, but also shared our enthusiasm and expectations for the game amidst laughter and laughter. No matter which team we support, at this moment, we are all loyal fans of football, cheering for the champion in our hearts. The finals of the America\'s Cup and the European Cup are not only a feast of football, but also a testimony of the unity and friendship of the members of the Knights Consortium.',
    },
    item36: {
      title: 'We gather at this special moment not only to celebrate the charm of football, but also to strengthen mutual connections and cooperation. Every game is a team effort, and every goal is our collective cheer! Thank you to all the members of the Knights Consortium who participated in this dinner. Your enthusiasm and energy added endless excitement to this evening. Let us continue to move forward hand in hand and welcome more victories and glory together!',
    },
}

export const industry = {
    title: "Investment industry",
    cat1: {
        name: "finance",
        item1: {
            title: "KNight Consortium investment team bought shares in a number of precious metals mining companies. These companies performed well during 2012 as rising precious metal prices caused their share prices to rise, resulting in huge gains for the KNights syndicate.",
        },
        item2: {
            title: "During the period from January to February 2012, the international gold price fluctuated. Financial analysts from KNight Consortium seized the opportunity to buy at low prices and made nearly 60% profit in just three months.",
        }
    },
    cat2: {
        name: "medical",
        item1: {
            title: "In 2020, the KNight Consortium  Medical Service Center was established, and the \"tumor immunotherapy\" treatment method jointly developed with Merck Pharmaceutical Group was successful.",
        },
        item2: {
            title: "KNight Consortium Medical Services Center and Novartis Pharmaceuticals have developed the cancer targeted drug Houng-592",
        }
    },
    cat3: {
        name: "metaverse",
        item1: {
            title: "In 2021, the KNight Consortium  web3.0 center was established to create a decentralized social platform, which attracted widespread attention and has been well received by users.",
        },
        item2: {
            title: "In the bull market of cryptocurrencies in January 2017, the KNight Consortium seized the opportunity and led the foundation members to achieve high profits, and the assets of all members quickly doubled.",
        }
    }
}


export const about = {
    title: "KNight Consortium unites the power of retail investors to realize the dream of financial freedom.",
    desc: "Gentlemen love fortune, in a proper way. The KNight Consortium is willing to guide this broad road to more people to discover, so that everyone can achieve initial prosperity and prosperity, and then achieve self-realization! Help individual investments escape the fate of being harvested by institutions and gain profits through professional and reliable capital operations, thereby helping team members realize their dreams of financial freedom.",
    service: {
        title: "Our syndicate capital objectives",
        item1: {
            title: "",
            desc: "Make every effort to promote global economic and social development and economic relations.<br><br><br><br><br><br>"
        },
        item2: {
            title: "",
            desc: "Promote the further development of charity to improve people's livelihood, provide national assistance to international refugees suffering from natural disasters, and use practical actions to help disadvantaged groups get out of trouble and let them live a happy and peaceful life."
        },
        item3: {
            title: "",
            desc: "Establish overseas service teams to assist the development of global agriculture, industry, economic construction, medical care, education, etc.<br><br><br><br>"
        }
    },
    timelinedata: {
        item1: {
            title: "2023-2024",
            desc: "The focus is on South America as the key area for assistance and relief by the KNight Capital Foundation, and it is expected to invest at least US$50 million in private assistance. Within three years, 1,000 KNight Capital Foundation offline charity offices and 5,000 employment opportunities will be established in South America."
        },
        item2: {
            title: "2025",
            desc: "The investment income and donation amount of the KNight Capital Foundation reaches US$100 million annually."
        },
        item3: {
            title: "2026",
            desc: "Achieve 5,000 KNight Capital Foundation offline charity offices around the world and more than 20,000 employment opportunities"
        },
        item4: {
            title: "2027",
            desc: "The investment income and donation amount of the KNight Capital Foundation is expected to reach more than 300 million US dollars. Through agricultural technology support and infrastructure construction, it will help more than 200 million people around the world escape the international poverty line, allowing them to obtain health, happiness and joy."
        }
    },
    counterdata: {
      title: "Current status and future plans of KNight Consortium",
      desc: "",
      item1: {
          title: 'Funds',
          unit: "Billion",
      },
      item2: {
          title: 'Users',
          unit: "Billion",
      },
      item3: {
          title: 'Invset',
          unit: "Partner",
      },
      item4: {
          title: 'Donate',
          unit: "Billion",
      }
    },
    teamdata: {
        title: "Members",
        item1: {
            name: "Remus John Worm",
            desc: "Master of Economics, University of Chicago School of Economics"
        },
        item2: {
            name: "Li Rongcheng",
            desc: "Master of Science and Master of Business Administration, University of Ottawa"
        },
        item3: {
            name: "Liu Caijun",
            desc: "Graduated from Peking University, Master of Management"
        }
    },
    article: {
        title: "By gathering the power of retail investors, Knight Consortium is committed to confronting institutions and fighting for more rights for ordinary investors.",
        desc: "In the stock market, there are two types of players, one is retail investors (individuals), and the other is institutional investors (bookmakers). Institutional financial power has always been squeezing the living space of retail investors, monopolizing and manipulating the stock market. The majority of retail investors have been aggrieved by this for a long time, and started an epic battle between retail investors and Wall Street short sellers - the \"GameStop Bloodbath on Wall Street\" incident.Once the power of retail investors is united, they are the most powerful force and have unquestionable dominance over the stock price trend."
    },
    certdata: {
        title:"National foundation certificates",
        item1: {
            title: "",
            desc: "USA",
        },
        item2: {
            title: "",
            desc: "Singapore",
        },
        item3: {
            title: "",
            desc: "U.K.",
        },
    },
    purpose: {
        title:"Knight Consortium Mission",
        btntitle:"PDF Download",
        image: require('@/assets/images/pdf/pdf_en.png'),
        downlink:"/en.pdf",
    }
}


export const digitalFour = {
    digita: {
        title:"Charity activities of the Knight Consortium"
    }
};

export const BlogDinner = {
    dinnerGrid: {
        title:"Knight consortium members have an offline dinner"
    }
};

export const homeNotice = {
    notice:{
        title:"Special announcement from the Knights consortium",
        desc:""
    }
}
