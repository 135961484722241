<template>
    <div class="row">    
        <div class="col-lg-4"
             v-for="(service, index) in serviceList"
             :key=index>
            <div :class="`service ${serviceStyle} ${checkTextAlign}`"
                 data-aos="fade-up"
                 data-aos-delay="70">
                <div class="icon1">
                    <!-- <img :src="service.image" alt="Service Images"/> -->
                    <!-- <img :src="require(service.imgurl)" alt="Service Images"/> -->
                    <img :src="$t('about.purpose.image')" alt="Service Images"/>
                </div>
            </div>
        </div>
        <div class="down-div" @click="downloadFile">
            <div class="down-but">
                <img class="down-img" src="../../../assets/images/pdf/pdf_img.png"/>
                <div>{{$t('about.purpose.btntitle')}}</div>
            </div>
        </div>
    </div>
</template>

<style lang="scss"scoped>
.row {
    justify-content: center;
    display: flex;
}

.icon1 {
    font-weight: 400;
    //   margin-bottom: 23px;
    color: var(--color-primary);
    text-align: center;
    border-radius: 100%;
    width: 65rem;
}

.col-lg-4 {
    // flex: 0 0 auto;
    width: 72.5rem;
}

.down-div {
    width: 30%;

    display: flex;
    justify-content: center;
    text-align: center;


    .down-but {
        cursor: pointer ;
        // background: #feae01;
        background: linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 95%/200% 100%;
        width: 20rem ;
        height: 4rem;
        margin-top: 20% ;

        display: flex;
        align-items: center; /* 垂直居中 */
        justify-content: center; /* 水平居中，如需要 */

        border-radius: 10px;
        font-weight: bold;
        color: #000;
    }

    .down-img {
        width: 2rem ;
        margin-right:2rem ;
    }
}

</style>

<script>
    import Icon from '../../icon/Icon'

    export default {
        name: 'ServicePurpose',
        components: {Icon},
        props: {
            serviceStyle: {
                type: String
            },
            iconSize: {
                type: String|Number,
                default: 62
            },
            textAlign: {
                type: String,
                default: 'left'
            }
        },
        data() {
            return {
                serviceList: [
                    {
                        icon: 'activity',
                        image: require('@/assets/images/pdf/pdf_cn.png'),
                        imgurl:"../../../assets/images/pdf/pdf_cn.png",
                    },
                    // {
                    //     icon: 'cast',
                    //     image: require('@/assets/images/service/cert-image-02.png'),
                    //     title: this.$t('about.certdata.item2.title'),
                    //     description: this.$t('about.certdata.item2.desc'),
                    //     flag: require('@/assets/images/flag/sgp.png'),
                    // },
                    // {
                    //     icon: 'map',
                    //     image: require('@/assets/images/service/cert-image-03.png'),
                    //     title: this.$t('about.certdata.item3.title'),
                    //     description: this.$t('about.certdata.item3.desc'),
                    //     flag: require('@/assets/images/flag/uk.png'),
                    // },
                ],
                // imageurl: require(this.$t('about.purpose.image')),
                // imageurl: require('../../../assets/images/pdf/pdf_cn.png'),
            }
        },
        computed: {
            checkTextAlign() {
                if (this.textAlign === 'left') return 'text-start';
                else if (this.textAlign === 'center') return 'text-center';
                else if (this.textAlign === 'end') return 'text-end';
                else return '';
            },
        },
        methods: {
            downloadFile() {
                let fileUrl = this.$t('about.purpose.downlink') ;
                window.open(fileUrl);
            }
        },
    }
</script>