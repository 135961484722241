<template>
    <div class="rn-service-area rn-section-gap">
        <div class="container">
            <div class="row">
                <div class="col-lg-12" style="text-align: center;">
                    <h2 class="title">{{$t('digitalFour.digita.title')}}</h2>
                </div>
            </div>
            
            <div class="row row--15">
                <!-- <div style="background-color: blue; width: 25%;" ></div> -->
                <div class="col-lg-6" style="margin-left: 25%;">
                    <Video :video-data="videoData" column="col-12"/>
                </div>
                <!-- <div style="background-color: blue; width: 25%;" ></div> -->
            </div>


            <!-- <div class="row row--40 align-items-center">
                <div class="col-lg-6">
                    <Video :video-data="videoData" column="col-12"/>
                </div>
                <div class="col-lg-6 mt_md--40 mt_sm--40">
                    <div class="content">
                        <div class="inner">
                            <h3 class="title">{{$t('digitalFour.digita.title')}}</h3> -->   
                        <!-- </div>
                    </div>
                </div>
            </div>  -->
        </div>
    </div>
</template>

<script>
    import Icon from '../../icon/Icon'
    import Video from "../video/Video";

    export default {
        name: 'AboutFour',
        components: {Video, Icon},
        props: {
            image: {}
        },
        data() {
            return {
                videoData: [
                    {
                        thumb: this.image,
                        src: require('../../../assets/videos/knink.mp4'),
                    }
                ]
            }
        }
    }
</script>