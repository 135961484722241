export const head = {
    home: "首頁",
    team: "會員簡介",
    case: "成功案例",
    industry: "投資業",
    contact: "聯絡我們",
    about: "關於我們"
};
  
export const foot = {
    services: {
        title: "服務",
        home: "服務中心"
    },
    case: {
        title: "成功案例",
        case: "成功案例"
    },
    company: {
        title: "公司介紹",
        contact: "聯絡我們",
        about: "關於我們"
    },
    resources: {
        title: "公司資源",
        industry: "投資業"
    },
    stay: {
        title: "與我們一起",
        desc: "世界各地有 2000+ 客戶訂閱我們"
    },
    copyright: {
        contact: "聯絡我們"
    }
};

export const home = {
    banner22: {
        title: "新年快樂 2024",
        desc: "騎士財團與您共度美好時光：財富增值，幸福滿滿"
    },
    banner1: {
        title: "騎士財團",
        desc: "從散戶到莊家，騎士財團領導者"
    },
    banner2: {
        title: "我們承諾",
        desc: "金融領域的公平競爭"
    },
    banner3: {
        title: "君子愛財，取之有道",
        desc: "騎士財團願將這條康莊大道指引給更多人發現，讓所有人都能實現初步小富小康，進而成就自我！"
    },
    servicefive: {
        title: "進步，發展，關懷是騎士財團的核心價值",
        // desc: "我们致力于推动全球经济关系和经济发展，通过整合全球知识、技术、资金与人才，成立海外服务团队并提供资金，以帮助全球农业，工业，经建，医疗，教育匮乏地区享有同样的发展机遇，齐心创造平等，尊严，繁荣，健康的未来愿景。",
        desc: "",
        item1: {
            title: "公司簡介",
            // desc: "骑士财团2010年成立于新加坡，初始成立资金约500万美金，如今管理资金规模达到10亿美元。",
            desc: "騎士財團於2010年在新加坡成立，初始資本約500萬美元，現管理資本10億美元。我們致力於推動全球經濟關係和經濟發展，透過整合全球知識建立海外服務團隊、技術、資金、人才。"
        },
        item2: {
            title: "公司文化",
            desc: "騎士財團的創立宗旨是匯聚愛心，傳遞溫暖，幫助人們參與慈善事業中，共同分享美好生活和發展機會。 增進全球各地之間的聯繫，並向遭受自然災害的國家和地區的難民救助。",
        },
        item3: {
            title: "公司愿景",
            desc: "與國際合作發展事務目標及重要內涵，永續發展，為機構願景。 依此方向，我們與國際社會，公民社會等開發援助，積極整合知識，技術，資金與人才，齊心創造平等，尊嚴，繁榮，健康的未來。",
        }
    },
    portfolio: {
        title: "騎士財團投資業與成功案例",
        desc: "全力增進全球間經濟社會發展以及經濟關係，成立海外服務工作團以協助全球的醫療，農業，工業，經建，教育等之發展。",
        cat1: {
            name: "金融",
            item1: {
                title: "2012年1月至2月期間國際金價震盪，騎士財團金融分析師把握時機低點買入，在短短的3個月內從中獲利將近60%。",
            },
            item2: {
                title: "騎士財團的國際投資團隊也買了一些貴金屬開採公司的股票。 這些公司在2012年期間表現良好，因為貴金屬價格上漲導致這些公司的股價上漲，為騎士財團帶來豐厚收益。",
            }
        },
        cat2: {
            name: "医疗",
            item1: {
                title: "2020年，騎士財團醫療服務中心成立，致力於研發創新藥物以改善人類健康。",
            },
            item2: {
                title: "騎士財團醫療服務中心攜手諾華製藥，研發了癌症標靶藥Houng-592",
            }
        },
        cat3: {
            name: "元宇宙",
            item1: {
                title: "2021成立騎士財團web3.0中心，創建了去中心化社交平台，吸引了廣泛的關注，並一直深受用戶好評。",
            },
            item2: {
                title: "2017年1月加密幣牛市，騎士財團把握機會，帶領基金會成員取得高額收益，所有成員資產快速翻倍",
            }
        }
    },
    counterdata: {
        title: "騎士財團現況及未來規劃",
        desc: "",
        item1: {
            title: '管理資金',
            unit: "億",
        },
        item2: {
            title: '使用者數',
            unit: "億",
        },
        item3: {
            title: '全球排名',
            unit: "强",
        },
        item4: {
            title: '捐款',
            unit: "億",
        }
      },
    leader: {
        title: "騎士財團聯盟成員簡介",
        desc: "財團資本的成員一起投資，做公益，並實現了財富自由以及為美好世界做出貢獻。",
        item1: {
            name: "張國富",
            desc: "英國牛津大學金融經濟學，取得碩士學位。 2010年8月創立騎士財團資本基金會，並擔任財團資本的主席"
        },
        item2: {
            name: "萊姆斯·約翰·沃姆",
            desc: "芝加哥大學經濟學院經濟碩士。 騎士財團資本基金會擔任副主席"
        },
        item3: {
            name: "李榮成",
            desc: "渥太華大學科學碩士及工商管理碩士學位。 騎士財團資本基金會擔任副主席。"
        }
    },
    blogpost: {
      title: "騎士財團社會公益",
      desc: "財富必將帶來流動的希望和愛，騎士財團效仿國外金融大亨，將基金會一部分收入全部用於慈善事業，救濟圍困，幫助發展，使得騎士財團贏得國際好評，贏得更多用戶和投資者的 信賴。",
      item1: {
        //   title: '2023年2月份土耳其7.8级地震，骑士财团向难民捐赠230万美元'
        title: '捐贈物資是我們對災民最直接、最實際的幫助。 2023年2月份土耳其7.8級地震，騎士財團捐贈230萬美元給難民重建家園。',
      },
      item2: {
        //   title: '2021年7月中国河南省郑州暴雨引发洪灾，骑士财团捐赠180万美金；'
        title: '2022年9月，騎士財團慈善團隊前往非洲，為貧困兒童提供關懷和幫助，確保兒童的基本生活需求得到滿足，點對點的資助方式能夠更直接地改善兒童的生活，並給予他們希望和鼓勵。',
      },
      item3: {
        //   title: '2019澳大利亚森林大火，财团资本捐赠120万美金用于救援物资和恢复。'
        title: '2023年2月土耳其7.8級地震，許多地區面臨嚴重的破壞和人員傷亡，騎士財團派遣救援隊，拯救受難群眾，給予他們溫暖和希望，讓他們感受到社會大家庭的溫情和關懷。',
      },
      item4: {
        title: '巴以戰爭中平民得不到任何保護，騎士財團確保人民獲得基本的救助，並積極提供重要的援助工作者。',
      },
      item5: {
        title: '在巴以戰爭中，補給線的開放，人道援助是關鍵而迫切的需求。 騎士財團抓緊窗口期運送對加薩民眾福祉至關重要的援助物資，並與國際人道組織協調，確保援助物資抵達。',
      },
      item6: {
        title: '隨著巴以戰爭的持續，傷亡人數的不斷增加。 受害人民的救援迫在眉睫，騎士財團積極組織救援團隊進行第一時間的救助。',
      },
      item7: {
        title: '騎士財團一直以來都以其專業援助著稱。 我們擁有經驗豐富的團隊，能夠迅速回應並提供切實可行的解決方案。 我們都以高效和透明的方式運作，確保每一分幫助都被合理而有效地利用。',
      },
      item8: {
        title: '選擇騎士財團的專業援助，意味著您選擇了可靠的合作夥伴，一個致力於改善生活、重建希望的團隊。 我們將不遺餘力地為受災者提供所需的支持，協助他們度過難關，邁向更好的未來。',
      },
      item9: {
        title: '在戰爭的陰霾中，戰後重建是我們必須踏出的關鍵一步。 重建社區，修復受損建築，幫助受災者重新融入社會，這些任務都需要專業的援助和堅定的支持。',
      },
      item10: {
        title: '騎士財團第一時間救援，為巴以戰爭受災者提供援助之光。 在巴以戰爭的挑戰面前，我們騎士財團堅定地站在受災者身邊。 作為一個致力於金融公平和社會責任的團隊，我們始終認為，關懷和援助是我們的責任之一。',
      },
      item11: {
        title: '在巴以戰爭中，我們深知受災者所面臨的挑戰與困難。 我們已經採取了緊急行動，向受災地區提供急需的物資援助。 這些物資包括食物、水、醫療用品和緊急救援設備，旨在為受災者提供急需的幫助和支援。',
      },
      item12: {
        title: '騎士財團一直以來都致力於支持戰後振興。 重建社區和幫助受災者是至關重要的。 因此，我們毫不猶豫地伸出援手，以確保那些受到戰爭影響的人們能夠重新建立自己的生活。',
      },
      item13: {
        title: '在戰亂的陰霾下，我們攜手助力，為受災者送去援手。 騎士財團一直秉持著社會責任感，致力於幫助那些在戰爭中受到影響的人們。 一起為戰爭受害者點亮生活的希望之光！',
      },
      item14: {
        title: '在戰後的艱難時刻，騎士財團願成為您可靠的夥伴，共同投入救助與重建的使命中。 我們深知每位受災者都需要關懷和援助，因此，我們積極參與各類救助行動，為那些飽受苦難的人們提供必要的支持。',
      },
      item15: {
        title: '騎士財團一直致力於傳播這項精神，透過慈善行動，援助項目，以及志工工作，我們努力改善生活，支持社區，讓世界變得更加溫暖。 只有共同努力，我們才能創造一個更美好、更有希望的未來。',
      },
      item16: {
        title: '阿根廷遭遇了超強暴風襲擊，导致16位无辜生命的失落。騎士財團深感痛心，第一時間響應，我們的專業團隊立即參與救災工作。在這場緊急情況下，我們堅守承諾，全力以赴為災區提供支援。',
      },
      item17: {
        title: '我們得知中國甘肅發生了6.2級地震，這場災難給當地居民帶來了巨大的困擾。在這個艱難時刻，騎士財團站在災區人民的一邊，致以最深切的慰問和支持。',
      },
      item18: {
        title: '在人生旅途中，有些時刻需要更多的關懷和支持。騎士財團以社會責任為己任，我們堅信幫助需要救援的人，是建立更美好社會的重要一環，而我們會一直在您身邊。',
      },
      item19: {
        title: '騎士財團又要出發了！ 看著地上整齊排列的牛奶、油、麵、餅乾等食物，他們即將送往當地的一戶困難家庭。 看著他們開心的樣子，我們知道這些物資將帶來多少溫暖和希望。 一份愛心，一份溫暖，騎士財團用行動詮釋著社會責任，讓我們共同期待這次的送達帶來的感動吧',
      },
      item20: {
        title: '近日，我們見證了騎士財團成員們的龐大隊伍，他們充滿活力和激情，帶著滿滿的食物，前往當地的社區，探望婦女、老人和兒童們。 這個團隊的每個成員都散發著無限的熱情和關愛，他們的出現為社區注入了一股暖流。 在活動中，我們看到了年輕男女們與老人、兒童們親切交流的場景，他們的笑容洋溢著快樂和感動。 無論是分發食物還是聊天，每個人都投入其中，用心帶給他人溫暖與關懷。 這支充滿活力的騎士財團隊伍，展現了團結友愛的精神，為社區注入了濃厚的愛。',
      },
      item21: {
        title: '近日，騎士財團的一群成員進行了感人至深的探望活動。 他們前往當地，探望了婦女、老人和兒童們，帶去了溫暖和關懷。 這次活動不僅是一次簡單的探望，更是對社區的真誠關懷與支持的體現。 在活動中，騎士財團的成員與受訪者親切交談，分享彼此的故事和經驗。 老人家面露微笑，孩子們歡笑，婦女們感激不盡。 他們用心傾聽，用愛心陪伴，讓每個受訪者都感受到了來自社區的溫暖與關懷。',
      },
      item22: {
        title: '騎士財團San Juan團隊在陽光明媚的日子裡，帶著滿滿的關愛，走進當地困難家庭。 團隊成員們分發了大米、食用油、空心粉、罐頭、麵包、餅乾等生活必需品，與每個家庭進行了親切的慰問和交談。 這次的物資發放不僅是對生命所需的實際支持，更是對每個家庭的心靈撫慰。 騎士志工們與被幫助的家庭分享著溫馨時光，傳遞彼此之間的關懷與希望。 被幫助的家庭紛紛表達對騎士志工們的感激之情，這份溫暖讓他們對未來充滿了信心與期待。 當志工們離開時，家庭們紛紛送上真摯的祝福，感謝騎士財團San Juan團隊為他們帶來的關懷與溫暖。',
      },
      item23: {
        title: '騎士財團成員向當地幾個貧困家庭伸出援手，為他們送去了豐富的生活必需品！ 在過去的一次活動中，騎士財團的熱心志工們攜帶著食用油、麵、小麥、粉等豐富的物資前往當地，將這些生活必需品送到了數家貧困家庭。 這不僅是一場簡單的捐贈，更是一次真誠的慰問與交流。 被幫助的家庭在收到這份溫暖的同時，表達出了對騎士志工們的深深感激。 生活中的困境似乎因為這份關愛而變得溫暖，充滿了希望與期待。',
      },
      item24: {
        title: '騎士財團的志工們帶著面，油，牛奶，糖等生活物資，踏上了一次感人至深的慈善之旅。 他們深入山區，為那些生活在山坡上、平時交通不便的困難家庭送去了實實在在的幫助。 這次的物資分發涵蓋了家庭日常生活的方方面面，包括食用油、麵粉、牛奶和糖等，確保每個家庭都能感受到騎士財團的溫暖關懷。 志工們不僅是物資的搬運工，更是陪伴、慰問和交流的傳遞者。 被協助的家庭位於偏遠山區，平時面臨交通不便、生活困難的挑戰。 因此，騎士志工們的到來對他們來說是一次及時雨，充滿了感激之情。 大家在交談中分享彼此的故事，讓這次的慈善活動更顯溫馨有意義。',
      },
      item25: {
        title: '近日，陽光燦爛，騎士財團的成員們帶著一桶桶食用油和果汁，來到當地效外，伸出援手，給那些需要支持和幫助的人們送去溫暖和關懷。在這美好的一天裡，我們不僅是騎士財團的成員，更是社區的一份子。我們深知身為一支團隊，責任和使命是我們肩上的擔子。因此，我們願意付出努力，用行動詮釋對社區的關懷與支持。讓我們一起將愛傳遞，讓這個世界充滿溫暖與希望。感謝您的支持，讓我們繼續攜手前行，為社區的發展和進步貢獻一份力量！',
      },
      item26: {
        title: '近日，我們騎士財團的團隊來到了阿根廷科爾多瓦，這個美麗的地方充滿了溫暖和希望。我們近30名成員組成的分隊匯聚在這裡，懷著一顆熱忱的心，帶著您的愛和支持，我們將幫助更多有需要的人，給他們帶去溫暖和關懷。在這個充滿愛的行動中，我們不僅是騎士財團的一員，也是您的代言人和使者。因為您的慷慨和愛心，我們能夠走到這裡，給更多人帶來感激之情。讓我們攜手並肩，共同為社會貢獻一份力量，讓世界充滿更多的溫暖與希望。感謝您的支持，讓我們繼續傳遞愛的力量！',
      }
    }
}



export const team = {
    leader1: {
        title: "總統",
        name: "張國富",
        desc: "英國牛津大學金融經濟學，取得碩士學位。 2010年8月創立騎士財團資本基金會，並擔任財團資本的主席"
    },
    leader2: {
        title: "副董事長兼法務總監",
        item1: {
            name: "萊姆斯·約翰·沃姆",
            desc: "芝加哥大學經濟學院經濟碩士。 騎士財團資本基金會擔任副主席"
        },
        item2: {
            name: "李榮成",
            desc: "渥太華大學科學碩士及工商管理碩士學位。 騎士財團資本基金會擔任副主席。"
        },
        item3: {
            name: "劉彩君",
            desc: "北京大學管理碩士畢業"
        }
    },
    leader3: {
        title: "金融分析師",
        item1: {
            name: "麥克斯韋",
            desc: "復旦大學國際金融學士"
        },
        item2: {
            name: "楊軍",
            desc: "畢業於新加坡南洋理工大學，金融專業"
        },
        item3: {
            name: "休伯特",
            desc: "英國倫敦大學，美國哈佛大學雙學位"
        }
    },
    leader4: {
        title: "顧問",
        item1: {
            name: "安菲亞",
            desc: "佛羅裡達大學學士"
        },
        item2: {
            name: "許浩",
            desc: "喬治華盛頓大學工程學位"
        },
        item3: {
            name: "多蘿西",
            desc: "印度理工學院工商管理碩士學位"
        }
    },
    leader5: {
        title: "核心成員",
        item1: {
            name: "孫馬",
            desc: "英國倫敦大學皇家霍洛威學院攻讀經濟學"
        },
        item2: {
            name: "伊馮娜",
            desc: "布魯塞爾自由大學碩士學位"
        },
        item3: {
            name: "嚴濤",
            desc: "華盛頓大學碩士（工程管理學位）"
        }
    }
}

export const casepage = {
    title: "社會公益介紹",
    item1: {
        title: "2023年2月份土耳其7.8級地震，騎士財團捐贈給難民230萬美元"
    },
    item2: {
        title: "2021年7月中國河南省鄭州暴雨引發洪災，騎士財團捐贈180萬美金；"
    },
    item3: {
        title: "2019澳洲森林大火，財團資本捐贈120萬美金用於救援物資與恢復。"
    },
    item4: {
        title: "2022年6月阿富汗地震造成死亡1,000餘人，2,000餘人受傷，財團資本捐贈100萬美元用於災後房屋重建，部分用於生活物資。",
    },
    item5: {
        title: "2023年2月份土耳其發生7.8級地震，造成死亡人數已超過4.1萬人，南部地區有150萬人無家可歸，需要建造至少50萬棟新房屋。 在敘利亞西北部，多達900萬人受到影響，至少6000人喪生，財團資本向難民捐贈230萬美元用於提供生活物資以及重建家園。",
    },
    item6: {
        title: "捐贈物資是我們對災民最直接、最實際的幫助。 2023年2月份土耳其7.8級地震，騎士財團捐贈230萬美元給難民重建家園。",
    },
    item7: {
        title: "2023年2月土耳其7.8級地震，許多地區面臨嚴重的破壞和人員傷亡，騎士財團派遣救援隊，拯救受難群眾，給予他們溫暖和希望，讓他們感受到社會大家庭的溫情和關懷。",
    },
    item8: {
        title: "2022年6月阿富汗地震造成死亡1,000餘人，2,000餘人受傷，財團資本捐贈100萬美元用於災後房屋重建，部分用於生活。",
    },
    item9: {
        title: "騎士財團積極參與關懷貧困兒童的慈善行動。 透過捐款和志願服務等方式，定期給予經濟援助和關懷。 這種關懷和幫助不僅改變了兒童的命運，也為整個社會的發展注入了更多正能量。",
    },
    item10: {
        title: "2022年9月，騎士財團慈善團隊前往非洲，為貧困兒童提供關懷和幫助，確保兒童的基本生活需求得到滿足，點對點的資助方式能夠更直接地改善兒童的生活，並給予他們希望和鼓勵。",
    },
    item11: {
        title: "巴以戰爭中平民得不到任何保護，騎士財團確保人民獲得基本的救助，並積極提供重要的援助工作者。",
    },
    item12: {
        title: "在巴以戰爭中，補給線的開放，人道援助是關鍵而迫切的需求。 騎士財團抓緊窗口期運送對加薩民眾福祉至關重要的援助物資，並與國際人道組織協調，確保援助物資抵達。",
    },
    item13: {
        title: "隨著巴以戰爭的持續，傷亡人數的不斷增加。 受害人民的救援迫在眉睫，騎士財團積極組織救援團隊進行第一時間的救助。",
    },
    item14: {
        title: "2023年7月，受第5號颱風「杜蘇芮」影響，中國京津冀等地出現極端強降雨，並引發洪水災害。 騎士財團展開緊急救援，為受災民眾提供更暢通及時的救助通道，幫助老年族群和困境人群脫離困境。",
    },
    item15: {
        title: '騎士財團第一時間救援，為巴以戰爭受災者提供援助之光。 在巴以戰爭的挑戰面前，我們騎士財團堅定地站在受災者身邊。 作為一個致力於金融公平和社會責任的團隊，我們始終認為，關懷和援助是我們的責任之一。',
    },
    item16: {
        title: '在巴以戰爭中，我們深知受災者所面臨的挑戰與困難。 我們已經採取了緊急行動，向受災地區提供急需的物資援助。 這些物資包括食物、水、醫療用品和緊急救援設備，旨在為受災者提供急需的幫助和支援。',
    },
    item17: {
        title: '騎士財團一直以來都致力於支持戰後振興。 重建社區和幫助受災者是至關重要的。 因此，我們毫不猶豫地伸出援手，以確保那些受到戰爭影響的人們能夠重新建立自己的生活。',
    },
    item18: {
        title: '騎士財團一直以來都以其專業援助著稱。 我們擁有經驗豐富的團隊，能夠迅速回應並提供切實可行的解決方案。 我們都以高效和透明的方式運作，確保每一分幫助都被合理而有效地利用。',
    },
    item19: {
        title: '選擇騎士財團的專業援助，意味著您選擇了可靠的合作夥伴，一個致力於改善生活、重建希望的團隊。 我們將不遺餘力地為受災者提供所需的支持，協助他們度過難關，邁向更好的未來。',
    },
    item20: {
        title: '在戰爭的陰霾中，戰後重建是我們必須踏出的關鍵一步。 重建社區，修復受損建築，幫助受災者重新融入社會，這些任務都需要專業的援助和堅定的支持。',
    },
    item21: {
      title: '在戰亂的陰霾下，我們攜手助力，為受災者送去援手。 騎士財團一直秉持著社會責任感，致力於幫助那些在戰爭中受到影響的人們。 一起為戰爭受害者點亮生活的希望之光！',
    },
    item22: {
      title: '在戰後的艱難時刻，騎士財團願成為您可靠的夥伴，共同投入救助與重建的使命中。 我們深知每位受災者都需要關懷和援助，因此，我們積極參與各類救助行動，為那些飽受苦難的人們提供必要的支持。',
    },
    item23: {
      title: '騎士財團一直致力於傳播這項精神，透過慈善行動，援助項目，以及志工工作，我們努力改善生活，支持社區，讓世界變得更加溫暖。 只有共同努力，我們才能創造一個更美好、更有希望的未來。',
    },
    item24: {
        title: '阿根廷遭遇了超強暴風襲擊，导致16位无辜生命的失落。騎士財團深感痛心，第一時間響應，我們的專業團隊立即參與救災工作。在這場緊急情況下，我們堅守承諾，全力以赴為災區提供支援。',
    },
    item25: {
        title: '我們得知中國甘肅發生了6.2級地震，這場災難給當地居民帶來了巨大的困擾。在這個艱難時刻，騎士財團站在災區人民的一邊，致以最深切的慰問和支持。',
    },
    item26: {
        title: '在人生旅途中，有些時刻需要更多的關懷和支持。騎士財團以社會責任為己任，我們堅信幫助需要救援的人，是建立更美好社會的重要一環，而我們會一直在您身邊。',
    },
    item27: {
        title: '騎士財團又要出發了！ 看著地上整齊排列的牛奶、油、麵、餅乾等食物，他們即將送往當地的一戶困難家庭。 看著他們開心的樣子，我們知道這些物資將帶來多少溫暖和希望。 一份愛心，一份溫暖，騎士財團用行動詮釋著社會責任，讓我們共同期待這次的送達帶來的感動吧',
    },
    item28: {
        title: '近日，我們見證了騎士財團成員們的龐大隊伍，他們充滿活力和激情，帶著滿滿的食物，前往當地的社區，探望婦女、老人和兒童們。 這個團隊的每個成員都散發著無限的熱情和關愛，他們的出現為社區注入了一股暖流。 在活動中，我們看到了年輕男女們與老人、兒童們親切交流的場景，他們的笑容洋溢著快樂和感動。 無論是分發食物還是聊天，每個人都投入其中，用心帶給他人溫暖與關懷。 這支充滿活力的騎士財團隊伍，展現了團結友愛的精神，為社區注入了濃厚的愛。',
    },
    item29: {
        title: '近日，騎士財團的一群成員進行了感人至深的探望活動。 他們前往當地，探望了婦女、老人和兒童們，帶去了溫暖和關懷。 這次活動不僅是一次簡單的探望，更是對社區的真誠關懷與支持的體現。 在活動中，騎士財團的成員與受訪者親切交談，分享彼此的故事和經驗。 老人家面露微笑，孩子們歡笑，婦女們感激不盡。 他們用心傾聽，用愛心陪伴，讓每個受訪者都感受到了來自社區的溫暖與關懷。',
    },
    item30: {
        title: '騎士財團San Juan團隊在陽光明媚的日子裡，帶著滿滿的關愛，走進當地困難家庭。 團隊成員們分發了大米、食用油、空心粉、罐頭、麵包、餅乾等生活必需品，與每個家庭進行了親切的慰問和交談。 這次的物資發放不僅是對生命所需的實際支持，更是對每個家庭的心靈撫慰。 騎士志工們與被幫助的家庭分享著溫馨時光，傳遞彼此之間的關懷與希望。 被幫助的家庭紛紛表達對騎士志工們的感激之情，這份溫暖讓他們對未來充滿了信心與期待。 當志工們離開時，家庭們紛紛送上真摯的祝福，感謝騎士財團San Juan團隊為他們帶來的關懷與溫暖。',
    },
    item31: {
        title: '騎士財團成員向當地幾個貧困家庭伸出援手，為他們送去了豐富的生活必需品！ 在過去的一次活動中，騎士財團的熱心志工們攜帶著食用油、麵、小麥、粉等豐富的物資前往當地，將這些生活必需品送到了數家貧困家庭。 這不僅是一場簡單的捐贈，更是一次真誠的慰問與交流。 被幫助的家庭在收到這份溫暖的同時，表達出了對騎士志工們的深深感激。 生活中的困境似乎因為這份關愛而變得溫暖，充滿了希望與期待。',
    },
    item32: {
        title: '騎士財團的志工們帶著面，油，牛奶，糖等生活物資，踏上了一次感人至深的慈善之旅。 他們深入山區，為那些生活在山坡上、平時交通不便的困難家庭送去了實實在在的幫助。 這次的物資分發涵蓋了家庭日常生活的方方面面，包括食用油、麵粉、牛奶和糖等，確保每個家庭都能感受到騎士財團的溫暖關懷。 志工們不僅是物資的搬運工，更是陪伴、慰問和交流的傳遞者。 被協助的家庭位於偏遠山區，平時面臨交通不便、生活困難的挑戰。 因此，騎士志工們的到來對他們來說是一次及時雨，充滿了感激之情。 大家在交談中分享彼此的故事，讓這次的慈善活動更顯溫馨有意義。',
    },
    item33: {
      title: '近日，陽光燦爛，騎士財團的成員們帶著一桶桶食用油和果汁，來到當地效外，伸出援手，給那些需要支持和幫助的人們送去溫暖和關懷。在這美好的一天裡，我們不僅是騎士財團的成員，更是社區的一份子。我們深知身為一支團隊，責任和使命是我們肩上的擔子。因此，我們願意付出努力，用行動詮釋對社區的關懷與支持。讓我們一起將愛傳遞，讓這個世界充滿溫暖與希望。感謝您的支持，讓我們繼續攜手前行，為社區的發展和進步貢獻一份力量！',
    },
    item34: {
      title: '近日，我們騎士財團的團隊來到了阿根廷科爾多瓦，這個美麗的地方充滿了溫暖和希望。我們近30名成員組成的分隊匯聚在這裡，懷著一顆熱忱的心，帶著您的愛和支持，我們將幫助更多有需要的人，給他們帶去溫暖和關懷。在這個充滿愛的行動中，我們不僅是騎士財團的一員，也是您的代言人和使者。因為您的慷慨和愛心，我們能夠走到這裡，給更多人帶來感激之情。讓我們攜手並肩，共同為社會貢獻一份力量，讓世界充滿更多的溫暖與希望。感謝您的支持，讓我們繼續傳遞愛的力量！',
    },
    item35: {
      title: '騎士財團成員線下聚餐，共享美洲杯和歐洲盃總決賽盛宴！我們不僅在餐桌上享受美食，還在歡聲笑語中分享對比賽的熱情與期待。無論是支持哪支球隊，這一刻，我們都是足球的忠實粉絲，為心中的冠軍吶喊助威。美洲盃和歐洲盃的總決賽不僅是足球的盛宴，更是騎士財團成員們團結與友誼的見證。',
    },
    item36: {
      title: '我們在這個特別的時刻相聚，不僅是為了慶祝足球的魅力，更是為了加強彼此的連結與合作。每一場比賽都是一次團隊的凝聚，每個進球都是我們共同的歡呼！感謝所有參與這次聚餐活動的騎士財團成員，你們的熱情和活力為這個夜晚增添了無盡的精彩。讓我們繼續攜手前行，共同迎接更多的勝利與榮耀！',
    },
}

export const industry = {
    title: "投資業",
    cat1: {
        name: "金融",
        item1: {
            title: "2012年1月至2月期間國際金價震盪，騎士財團金融分析師把握時機低點買入，在短短的3個月內從中獲利將近60%。",
        },
        item2: {
            title: "騎士財團的國際投資團隊也買了一些貴金屬開採公司的股票。 這些公司在2012年期間表現良好，因為貴金屬價格上漲導致這些公司的股價上漲，為騎士財團帶來豐厚收益。",
        }
    },
    cat2: {
        name: "医疗",
        item1: {
            title: "2020年，騎士財團醫療服務中心成立，致力於研發創新藥物以改善人類健康。",
        },
        item2: {
            title: "騎士財團醫療服務中心攜手諾華製藥，研發了癌症標靶藥Houng-592",
        }
    },
    cat3: {
        name: "元宇宙",
        item1: {
            title: "2021成立騎士財團web3.0中心，創建了去中心化社交平台，吸引了廣泛的關注，並一直深受用戶好評。",
        },
        item2: {
            title: "2017年1月加密幣牛市，騎士財團把握機會，帶領基金會成員取得高額收益，所有成員資產快速翻倍",
        }
    }
}


export const about = {
    title: "騎士財團聯合散戶力量，實現財富自由的夢想",
    desc: "君子愛財，取之有道。 騎士財團願將這條康莊大道指引給更多人發現，讓所有人都能實現初步小富小康，進而成就自我！ 幫助個人投資擺脫被機構收割的命運，並透過專業可靠的資本運作獲取獲利，從而幫助團隊成員實現財富自由的夢想。",
    service: {
        title: "我們財團資本的目標",
        item1: {
            title: "",
            desc: "全力增進全球間經濟社會發展及經濟關係。"
        },
        item2: {
            title: "",
            desc: "增進慈善事業的進一步發展，去改善民生，給予國際難民遭受自然災害的國家救助，以實際的行動去幫助困難群體走出困境，讓他們過上幸福與和平的生活。"
        },
        item3: {
            title: "",
            desc: "成立海外服務工作團以協助全球的農業，工業，經建，醫療，教育等之發展。"
        }
    },
    timelinedata: {
        item1: {
            title: "2023-2024",
            desc: "重心將南美洲地區作為騎士財團資本基金會幫扶救濟重點地區，預計至少投入資金5,000萬美金以上用於民間援助。 在三年內將在南美洲地區成立1000個騎士財團資本基金會線下慈善辦事處和5000個就業機會"
        },
        item2: {
            title: "2025",
            desc: "騎士財團資本基金會投資所得及捐款金額年達1億美金。"
        },
        item3: {
            title: "2026",
            desc: "實現全球5000個騎士財團資本基金會線下慈善辦事處及20,000個以上就業機會"
        },
        item4: {
            title: "2027",
            desc: "騎士財團資本基金會投資所得以及捐贈資金金額預計達到3億美金以上，透過農業技術扶持與基礎設置建設，幫助全球2億人口以上脫離國際貧窮線，讓他們得到健康，幸福與快樂"
        }
    },
    teamdata: {
        title: "無匹配",
        item1: {
            name: "萊姆斯·約翰·沃姆",
            desc: "芝加哥大學經濟學院經濟碩士。 騎士財團資本基金會擔任副主席"
        },
        item2: {
            name: "李榮成",
            desc: "渥太華大學科學碩士及工商管理碩士學位。 騎士財團資本基金會擔任副主席。"
        },
        item3: {
            name: "劉彩君",
            desc: "北京大學管理碩士畢業"
        }
    },
    article: {
        title: "騎士財團透過匯聚廣在散戶的力量，致力於與機構對抗，為一般投資人爭取更多權益的存在。",
        desc: "在股市中，有兩類玩家，一種是散戶（個人），另一種是機構投資者（莊家），一直以來，機構資金力量都在擠壓散戶的生存空間，壟斷、操縱股票市場 。 廣大散戶對此憤憤不平已久，開啟場散戶與華爾街做空機構的史詩級鬥爭－「遊戲驛站血洗華爾街」事件。 散戶的力量一旦團結起來，就是最強大的力量，對股價的走勢擁有毫無疑問的主導權。"
    },
    certdata: {
        title:"各國基金會證書",
        item1: {
            title: "",
            desc: "美國",
        },
        item2: {
            title: "",
            desc: "新加坡",
        },
        item3: {
            title: "",
            desc: "英國",
        },
    },
    purpose: {
        title:"騎士財團宗旨",
        btntitle:"PDF下載",
        image: require('@/assets/images/pdf/pdf_cn.png'),
        downlink:"/cn.pdf",
    }
}

export const digitalFour = {
    digita: {
        title:"騎士財團的慈善活動"
    }
};

export const BlogDinner = {
    dinnerGrid: {
        title:"騎士財團成員線下聚餐"
    }
};

export const homeNotice = {
    notice:{
        title:"騎士財團特別通告",
        desc:""
    }
}