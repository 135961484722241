//西班牙
export const head = {
  home: "Hogar",
  team: "Equipo",
  case: "Caso",
  industry: "Industria",
  contact: "Contacto",
  about: "Acerca de"
};
  
export const foot = {
  services: {
      title: "Servicios",
      home: "Hogar"
  },
  case: {
      title: "Caso",
      case: "Caso"
  },
  company: {
      title: "Compañía",
      contact: "Contacto",
      about: "Acerca de"
  },
  resources: {
      title: "Recursos",
      industry: "Industria"
  },
  stay: {
      title: "Quédate con nosotros.",
      desc: "2000+ Nuestros clientes se suscriben alrededor del mundo."
  },
  copyright: {
      contact: "Contacta con nosotros"
  }
};

export const home = {
  banner22: {
      title: "Feliz año nuevo 2024",
      desc: "El Consorcio Caballero pasa un buen rato contigo: aumentando riqueza y lleno de felicidad"
  },
  banner1: {
      title: "Consorcio Knight",
      desc: "Desde inversores minoristas hasta banqueros, el líder de KNight Consortium"
  },
  banner2: {
      title: "Nuestro compromiso",
      desc: "Competencia leal en el sector financiero"
  },
  banner3: {
      title: "Los caballeros aman la fortuna, como es debido.",
      desc: "¡El Knight Consortium está dispuesto a guiar este amplio camino para que más personas lo descubran, para que todos puedan lograr prosperidad y prosperidad inicial, y luego lograr la autorrealización!"
  },
  servicefive: {
      title: "Progreso, desarrollo y cuidado son los valores fundamentales de KNight Consortium",
    //   desc: "We are committed to promoting global economic relations and economic development. By integrating global knowledge, technology, funds and talents, we have established overseas service teams and provided funds to help areas lacking in agriculture, industry, economic construction, medical care and education around the world enjoy the same development opportunities. , work together to create a future vision of equality, dignity, prosperity and health.",
      desc: "",
      item1: {
          title: "Perfil de la empresa",
        //   desc: "KNight Consortium was established in Singapore in 2010 with an initial capital of approximately US$5 million, and now has a managed capital of US$1 billion.",
        desc: "KNight Consortium se estableció en Singapur en 2010 con un capital inicial de aproximadamente 5 millones de dólares estadounidenses y ahora cuenta con un capital administrado de mil millones de dólares estadounidenses. Estamos comprometidos a promover las relaciones económicas globales y el desarrollo económico, y a establecer equipos de servicio en el extranjero mediante la integración del conocimiento global. , tecnología, fondos y talentos."
      },
      item2: {
          title: "Cultura de la empresa",
          desc: "El propósito fundacional de KNight Consortium es reunir amor, transmitir calidez, ayudar a las personas a participar en obras de caridad y compartir juntos una vida mejor y oportunidades de desarrollo. Promover conexiones en todo el mundo y brindar asistencia a refugiados en países y regiones afectados por desastres naturales.<br><br><br>",
      },
      item3: {
          title: "Visión de la empresa",
          desc: "Los objetivos y connotaciones importantes de desarrollar asuntos con cooperación internacional y desarrollo sostenible son la visión de la organización. En esta dirección, trabajamos con la comunidad internacional, la sociedad civil y otras organizaciones de ayuda al desarrollo para integrar activamente conocimientos, tecnología, fondos y talentos para crear un futuro de igualdad, dignidad, prosperidad y salud.",
      }
  },
  portfolio: {
      title: "Industria de inversión del Consorcio KNight y casos de éxito",
      desc: "Nos esforzamos por promover el desarrollo económico y social global y las relaciones económicas, y establecer equipos de servicio en el extranjero para ayudar al desarrollo de la construcción médica, agrícola, industrial, económica, la educación, etc.",
      cat1: {
          name: "finance",
          item1: {
              title: "El equipo de inversión de KNight Consortium compró acciones de varias empresas mineras de metales preciosos. Estas empresas tuvieron un buen desempeño durante 2012, ya que el aumento de los precios de los metales preciosos hizo que los precios de sus acciones subieran, lo que resultó en enormes ganancias para el sindicato KNights.",
          },
          item2: {
              title: "Durante el período de enero a febrero de 2012, el precio internacional del oro fluctuó. Los analistas financieros de KNight Consortium aprovecharon la oportunidad para comprar a precios bajos y obtuvieron casi un 60% de ganancias en sólo tres meses.",
          }
      },
      cat2: {
          name: "medical",
          item1: {
              title: "En 2020, se estableció el Centro de servicios médicos del KNight Consortium y el método de tratamiento de \"inmunoterapia tumoral\" desarrollado conjuntamente con Merck Pharmaceutical Group tuvo éxito.",
          },
          item2: {
              title: "KNight Consortium Medical Services Center y Novartis Pharmaceuticals han desarrollado el fármaco dirigido contra el cáncer Houng-592",
          }
      },
      cat3: {
          name: "metaverse",
          item1: {
              title: "En 2021, se creó el centro web3.0 de KNight Consortium para crear una plataforma social descentralizada, que atrajo una gran atención y fue bien recibida por los usuarios.",
          },
          item2: {
              title: "En el mercado alcista de las criptomonedas en enero de 2017, el Consorcio KNight aprovechó la oportunidad y llevó a los miembros de la fundación a lograr altas ganancias, y los activos de todos los miembros se duplicaron rápidamente.",
          }
      }
  },
  counterdata: {
    title: "Estado actual y planes futuros de KNight Consortium",
    desc: "",
    item1: {
        title: 'Fondos',
        unit: "mil millones",
    },
    item2: {
        title: 'Usuarios',
        unit: "mil millones",
    },
    item3: {
        title: 'Invertir',
        unit: "Pareja",
    },
    item4: {
        title: 'Donar',
        unit: "mil millones",
    }
  },
  leader: {
      title: "Introducción a los miembros de la Alianza KNight Consortium",
      desc: "Los miembros del capital del sindicato invierten juntos, hacen obras de caridad, logran la libertad financiera y contribuyen a un mundo mejor.",
      item1: {
          name: "Zhang Guofu",
          desc: "Obtuvo una maestría en economía financiera de la Universidad de Oxford, Reino Unido. En agosto de 2010, fundó la Fundación KNight Consortium Capital y se desempeñó como presidente de Consortium Capital."
      },
      item2: {
          name: "Remus John Worm",
          desc: "Maestría en Economía de la Escuela de Economía de la Universidad de Chicago. Se desempeñó como vicepresidente de la Fundación Knight Capital <br><br><br>"
      },
      item3: {
          name: "Li Rongcheng",
          desc: "Maestría en Ciencias y Maestría en Administración de Empresas de la Universidad de Ottawa. Se desempeñó como vicepresidente de la Fundación Knight Capital<br><br>"
      }
  },
  blogpost: {
    title: "Consorcio KNight Bienestar Social",
    desc: "La riqueza definitivamente traerá esperanza y amor fluidos. El Consorcio KNight sigue el ejemplo de los magnates financieros extranjeros y utiliza parte de los ingresos de la fundación con fines benéficos para aliviar el asedio y ayudar al desarrollo, lo que hace que el Consorcio KNight gane el reconocimiento internacional y la confianza de más usuarios e inversores.",
    item1: {
        // title: "After the 7.8-magnitude earthquake in Turkey in February 2023, the KNight Consortium donated US$2.3 million to refugees"
        title: 'La donación de materiales es nuestra ayuda más directa y práctica a las víctimas. En febrero de 2023, Turquía sufrió un terremoto de magnitud 7,8 y la Fundación Knights donó 2,3 millones de dólares a refugiados para reconstruir sus hogares.',
    },
    item2: {
        // title: "In July 2021, heavy rains caused floods in Zhengzhou, Henan Province, China. The KNight Consortium donated US$1.8 million;"
        title: 'En septiembre de 2022, el equipo benéfico de la Fundación Knight viajó a África para brindar atención y ayuda a los niños pobres y garantizar que se satisfagan sus necesidades básicas de vida. El método de financiación entre pares puede mejorar más directamente las vidas de los niños y darles esperanza y aliento.',
    },
    item3: {
        // title: "During the 2019 Australian forest fires, Consortium Capital donated US$1.2 million for relief supplies and recovery."
        title: 'En febrero de 2023, Turquía sufrió un terremoto de magnitud 7,8 y muchas zonas sufrieron graves daños y víctimas. La Fundación Knight envió equipos de rescate para rescatar a las víctimas, darles calidez y esperanza y hacerles sentir la calidez y el cuidado de la familia social.',
    },
    item4: {
      title: 'Los civiles no recibieron ninguna protección durante la guerra palestino-israelí. El Consorcio de los Caballeros se aseguró de que la gente recibiera asistencia básica y proporcionó activamente importantes trabajadores humanitarios.',
    },
    item5: {
      title: 'En la guerra palestino-israelí, la apertura de líneas de suministro y asistencia humanitaria son necesidades críticas y urgentes. El Consorcio de Caballeros aprovecha el período de ventana para entregar suministros de ayuda que son cruciales para el bienestar del pueblo de Gaza y coordina con organizaciones humanitarias internacionales para garantizar la llegada de suministros de ayuda.',
    },
    item6: {
      title: 'A medida que continúa la guerra palestino-israelí, el número de víctimas sigue aumentando. El rescate de las víctimas es inminente y el Knight Consortium organiza activamente equipos de rescate para brindar un rescate inmediato.',
    },
    item7: {
        title: 'El Grupo Knight siempre ha sido conocido por su asistencia profesional. Contamos con un equipo experimentado que puede responder rápidamente y brindar soluciones prácticas. Todos operamos de manera eficiente y transparente para garantizar que toda la ayuda se utilice de manera adecuada y efectiva.',
    },
    item8: {
        title: 'Elegir la asistencia profesional de Knight Consortium significa que ha elegido un socio confiable, un equipo dedicado a mejorar vidas y reconstruir la esperanza. No escatimaremos esfuerzos para brindar a las víctimas el apoyo que necesitan para ayudarlas a superar las dificultades y avanzar hacia un futuro mejor.',
    },
    item9: {
        title: 'En medio de la bruma de la guerra, la reconstrucción de posguerra es un paso fundamental que debemos dar. La reconstrucción de comunidades, la reparación de edificios dañados y la reintegración de las víctimas a la sociedad requieren asistencia profesional y apoyo comprometido.',
    },
    item10: {
        title: 'La Fundación Knight acudió inmediatamente al rescate y proporcionó un rayo de ayuda a las víctimas de la guerra palestino-israelí. Frente a los desafíos de la guerra palestino-israelí, nuestra Fundación de Caballeros apoya firmemente a las víctimas. Como equipo comprometido con la equidad financiera y la responsabilidad social, siempre hemos considerado el cuidado y la asistencia como una de nuestras responsabilidades.',
    },
    item11: {
        title: 'En la guerra palestino-israelí, somos muy conscientes de los desafíos y dificultades que enfrentan las víctimas. Hemos tomado medidas de emergencia para proporcionar asistencia material que se necesita con urgencia a las zonas afectadas. Estos suministros incluyen alimentos, agua, suministros médicos y equipo de rescate de emergencia y están diseñados para brindar ayuda y apoyo muy necesarios a los afectados por el desastre.',
    },
    item12: {
        title: 'La Knights Foundation lleva mucho tiempo comprometida con apoyar la revitalización de la posguerra. Reconstruir comunidades y ayudar a los afectados es crucial. Por lo tanto, no dudamos en tender la mano para garantizar que los afectados por la guerra puedan reconstruir sus vidas.',
    },
    item13: {
      title: 'En medio de la bruma de la guerra, unimos nuestras manos para brindar ayuda a las víctimas. La Knights Foundation siempre ha defendido un sentido de responsabilidad social y está comprometida a ayudar a los afectados por la guerra. ¡Iluminemos las vidas de las víctimas de la guerra con la luz de la esperanza!',
    },
    item14: {
      title: 'En los momentos difíciles después de la guerra, el Consorcio de Caballeros está dispuesto a ser su socio confiable y unirse en la misión de rescate y reconstrucción. Sabemos que cada víctima de un desastre necesita atención y asistencia, por eso participamos activamente en diversas operaciones de socorro para brindar el apoyo necesario a quienes sufren.',
    },
    item15: {
      title: 'La Fundación Knights se ha comprometido a difundir este espíritu. A través de acciones caritativas, proyectos de ayuda y trabajo voluntario, nos esforzamos por mejorar vidas, apoyar a las comunidades y hacer del mundo un lugar más cálido. Sólo trabajando juntos podremos crear un futuro mejor y más esperanzador.',
    },
    item16: {
        title: 'Argentina fue azotada por una tormenta súper fuerte, que provocó la pérdida de 16 vidas inocentes. El Consorcio Knight se entristeció profundamente y respondió de inmediato, y nuestro equipo profesional participó de inmediato en el trabajo de socorro en caso de desastre. Durante esta emergencia, seguimos comprometidos a apoyar a las áreas afectadas.',
    },
    item17: {
        title: 'Nos enteramos de que se produjo un terremoto de magnitud 6,2 en Gansu, China. Este desastre causó gran angustia a los residentes locales. En este momento difícil, la Fundación Knights está del lado de las personas en la zona del desastre y les extiende su más sentido pésame y apoyo.',
    },
    item18: {
        title: 'Hay momentos en el viaje de la vida que requieren más cuidado y apoyo. La Fundación Knight asume la responsabilidad social como su propia responsabilidad. Creemos firmemente que ayudar a los necesitados es una parte importante para construir una sociedad mejor y siempre estaremos a su lado.',
    },
    item19: {
        title: '¡El Knight Consortium está a punto de partir de nuevo! Al ver la leche, el aceite, los fideos, las galletas y otros alimentos cuidadosamente dispuestos en el suelo, estaban a punto de entregárselos a una familia local necesitada. Al ver sus caras felices, sabemos cuánta calidez y esperanza traerán estos suministros. Un amor, una calidez, la Fundación Knight interpreta la responsabilidad social con acciones, esperemos con ansias el toque que trae esta entrega.',
    },
    item20: {
        title: 'Recientemente, hemos sido testigos de un gran equipo de miembros del consorcio de caballeros, llenos de energía y pasión, llevando cargas de alimentos a las comunidades locales para visitar a mujeres, ancianos y niños. Cada miembro de este equipo irradia entusiasmo y atención ilimitados, y su presencia inyecta una corriente cálida en la comunidad. Durante el evento vimos a hombres y mujeres jóvenes interactuando cordialmente con ancianos y niños, sus sonrisas estaban llenas de alegría y cariño. Ya sea distribuyendo comida o charlando, todos participan y brindan calidez y atención a los demás con corazón. Este equipo dinámico de Knight Financial Group demuestra el espíritu de unidad y amistad e inyecta un fuerte amor a la comunidad.',
    },
    item21: {
        title: 'Recientemente, un grupo de miembros del Consorcio Knight realizó una conmovedora visita. Fueron al área local y visitaron a mujeres, ancianos y niños, brindándoles calidez y atención. Este evento no es sólo una simple visita, sino también un reflejo de sincero cuidado y apoyo a la comunidad. Durante el evento, los miembros de la Fundación Knights sostuvieron conversaciones cordiales con los entrevistados y compartieron historias y experiencias de cada uno. Los ancianos sonreían, los niños reían y las mujeres estaban agradecidas. Escucharon atentamente y acompañaron con amor, haciendo que cada entrevistado sintiera la calidez y el cariño de la comunidad.',
    },
    item22: {
        title: 'En un día soleado, el equipo de San Juan del Consorcio Knight visitó con total atención a familias locales necesitadas. Los miembros del equipo distribuyeron artículos de primera necesidad como arroz, aceite de cocina, fideos huecos, latas, pan y galletas, y llevaron a cabo cordiales condolencias y conversaciones con cada familia. La distribución material de este tiempo no es sólo un apoyo práctico para las necesidades diarias, sino también un consuelo espiritual para cada familia. Los voluntarios de Knight comparten momentos cálidos con las familias que reciben ayuda, transmitiéndose cariño y esperanza unos a otros. Las familias ayudadas expresaron su agradecimiento a los voluntarios Caballeros, esta calidez los llenó de confianza y expectativas para el futuro. Cuando los voluntarios se fueron, las familias enviaron sus más sinceras bendiciones y agradecieron al equipo de San Juan de la Fundación Caballeros por el cuidado y calidez que les brindaron.',
    },
    item23: {
        title: '¡Los miembros de la Fundación Knights extendieron una mano amiga a varias familias pobres locales y les enviaron abundantes artículos de primera necesidad! En un evento anterior, entusiastas voluntarios de la Fundación Knights fueron al área local llevando abundantes suministros como aceite de cocina, fideos, trigo y harina, y entregaron estos artículos de primera necesidad a varias familias pobres. Esta no es sólo una simple donación, sino también un sincero pésame y una comunicación. Al recibir esta calidez, las familias ayudadas expresaron su profunda gratitud a los voluntarios de los Caballeros. Las dificultades de la vida parecen volverse más cálidas y llenas de esperanza y expectativa gracias a este cuidado.',
    },
    item24: {
        title: 'Los voluntarios de la Fundación Knight se embarcaron en un conmovedor viaje benéfico con artículos de primera necesidad como pan, aceite, leche, azúcar, etc. Se adentraron en las zonas montañosas y brindaron ayuda tangible a las familias necesitadas que vivían en las laderas y tenían transporte limitado. Esta distribución de material cubre todos los aspectos de la vida diaria familiar, incluido el aceite de cocina, la harina, la leche, el azúcar, etc., asegurando que cada familia pueda sentir el cálido cuidado de la Fundación Knights. Los voluntarios no sólo son portadores de suministros, sino también de compañía, condolencias y comunicación. Las familias que reciben ayuda están ubicadas en zonas montañosas remotas y a menudo enfrentan desafíos como transporte inconveniente y condiciones de vida difíciles. Por lo tanto, la llegada de los Caballeros voluntarios fue una bendición oportuna para ellos y los llenó de gratitud. Todos compartieron las historias de los demás durante la conversación, lo que hizo que este evento benéfico fuera más cálido y significativo.',
    },
    item25: {
      title: 'Recientemente, el sol brillaba intensamente y los miembros del Consorcio Knight llegaron al área local con cubos de aceite de cocina y jugo para echar una mano y enviar calidez y atención a quienes necesitaban apoyo y ayuda. En este hermoso día, no sólo somos miembros del Consorcio de Caballeros, sino también parte de la comunidad. Sabemos que, como equipo, nuestras responsabilidades y misiones están sobre nuestros hombros. Por lo tanto, estamos dispuestos a trabajar duro y utilizar acciones para demostrar nuestro cuidado y apoyo a la comunidad. Difundamos nuestro amor juntos y llenemos el mundo de calidez y esperanza. ¡Gracias por su apoyo, sigamos avanzando juntos y contribuyendo al desarrollo y progreso de la comunidad!',
    },
    item26: {
      title: 'Recientemente, nuestro equipo del Consorcio Knight llegó a Córdoba, Argentina. Este hermoso lugar está lleno de calidez y esperanza. Nuestro equipo de casi 30 miembros está reunido aquí. Con un corazón cálido y su amor y apoyo, ayudaremos a más personas necesitadas y les brindaremos calidez y atención. En esta acción llena de amor, no sólo somos miembros del Consorcio de Caballeros, sino también su portavoz y mensajero. Gracias a su generosidad y amor, podemos llegar hasta aquí y llevar gratitud a más personas. Unamos nuestras manos para contribuir a la sociedad y llenar el mundo de más calidez y esperanza. ¡Gracias por su apoyo, sigamos difundiendo el poder del amor!',
    }
  }
}

export const team = {
    leader1: {
        title: "Presidente",
        name: "Zhang Guofu",
        desc: "Maestría en Economía Financiera de la Universidad de Oxford, Reino Unido"
    },
    leader2: {
        title: "Vicepresidente y Director Jurídico",
        item1: {
            name: "Remus John Worm",
            desc: "Maestría en Economía, Facultad de Economía de la Universidad de Chicago"
        },
        item2: {
            name: "Li Rongcheng",
            desc: "Maestría en Ciencias y Maestría en Administración de Empresas, Universidad de Ottawa"
        },
        item3: {
            name: "Liu Caijun",
            desc: "Graduado de la Universidad de Pekín, Maestría en Gestión"
        }
    },
    leader3: {
        title: "Analista financiero",
        item1: {
            name: "Maxwell",
            desc: "Licenciatura en Finanzas Internacionales, Universidad de Fudan"
        },
        item2: {
            name: "Yang Jun",
            desc: "Graduado de la Universidad Tecnológica de Nanyang, Singapur, con especialización en Finanzas"
        },
        item3: {
            name: "Hubert",
            desc: "Doble titulación de la Universidad de Londres, Reino Unido, y la Universidad de Harvard, EE.UU."
        }
    },
    leader4: {
        title: "Consultor",
        item1: {
            name: "Анфия",
            desc: "Licenciatura, Universidad de Florida"
        },
        item2: {
            name: "Xu Hao",
            desc: "Licenciatura en Ingeniería de la Universidad George Washington"
        },
        item3: {
            name: "Dorothy",
            desc: "MBA del Instituto Indio de Tecnología"
        }
    },
    leader5: {
        title: "Miembro principal",
        item1: {
            name: "sun horse",
            desc: "Estudia Economía en Royal Holloway, Universidad de Londres, Reino Unido"
        },
        item2: {
            name: "Yvonne",
            desc: "Máster por la Universidad Libre de Bruselas."
        },
        item3: {
            name: "Yan Tao",
            desc: "Maestría por la Universidad de Washington (Grado en Gestión de Ingeniería)"
        }
    }
}

export const casepage = {
    title: "Introducción al bienestar social.",
    item1: {
        title: "Tras el terremoto de magnitud 7,8 que azotó Turquía en febrero de 2023, el consorcio KNight donó 2,3 millones de dólares a los refugiados"
    },
    item2: {
        title: "En julio de 2021, fuertes lluvias provocaron inundaciones en Zhengzhou, provincia de Henan, China. El Consorcio KNight donó 1,8 millones de dólares;"
    },
    item3: {
        title: "Durante los incendios forestales australianos de 2019, Consortium Capital donó 1,2 millones de dólares para suministros de socorro y recuperación."
    },
    item4: {
        title: "En febrero de 2023, un terremoto de magnitud 7,8 sacudió Turquía y provocó más de 41.000 muertes. 1,5 millones de personas quedaron sin hogar en la región sur y fue necesario construir al menos 500.000 nuevas viviendas. En el noroeste de Siria, hasta 9 millones de personas se han visto afectadas y al menos 6.000 han perdido la vida. Consortium Capital donó 2,3 millones de dólares a refugiados para proporcionarles materiales de vida y reconstruir sus hogares.",
    },
    item5: {
        title: "En junio de 2022, el terremoto en Afganistán mató a más de 1.000 personas e hirió a más de 2.000. El capital del consorcio donó 1 millón de dólares para la reconstrucción de viviendas después del desastre, parte del cual se utilizó para las necesidades diarias.",
    },
    item6: {
        title: "La donación de materiales es nuestra ayuda más directa y práctica a las víctimas. En febrero de 2023, Turquía sufrió un terremoto de magnitud 7,8 y la Fundación Knights donó 2,3 millones de dólares a refugiados para reconstruir sus hogares.",
    },
    item7: {
        title: "En febrero de 2023, Turquía sufrió un terremoto de magnitud 7,8 y muchas zonas sufrieron graves daños y víctimas. La Fundación Knight envió equipos de rescate para rescatar a las víctimas, darles calidez y esperanza y hacerles sentir la calidez y el cuidado de la familia social.",
    },
    item8: {
        title: "El terremoto que azotó Afganistán en junio de 2022 mató a más de 1.000 personas e hirió a más de 2.000. El capital del consorcio donó 1 millón de dólares para la reconstrucción de viviendas después del desastre, parte del cual se utilizó para cubrir gastos de manutención.",
    },
    item9: {
        title: "La Fundación Knights participa activamente en acciones benéficas que atienden a niños pobres. Se proporciona asistencia financiera y atención periódica a través de donaciones y servicios voluntarios. Este tipo de atención y ayuda no sólo cambia el destino de los niños, sino que también inyecta más energía positiva al desarrollo de toda la sociedad.",
    },
    item10: {
        title: "En septiembre de 2022, el equipo benéfico de la Fundación Knight viajó a África para brindar atención y ayuda a los niños pobres y garantizar que se cubran sus necesidades básicas de vida. El método de financiación entre pares puede mejorar más directamente las vidas de los niños y darles esperanza y aliento.",
    },
    item11: {
        title: "Los civiles no recibieron ninguna protección durante la guerra palestino-israelí. El Consorcio de los Caballeros se aseguró de que la gente recibiera asistencia básica y proporcionó activamente importantes trabajadores humanitarios.",
    },
    item12: {
        title: "En la guerra palestino-israelí, la apertura de líneas de suministro y asistencia humanitaria son necesidades críticas y urgentes. El Consorcio de Caballeros aprovecha el período de ventana para entregar suministros de ayuda que son cruciales para el bienestar del pueblo de Gaza y coordina con organizaciones humanitarias internacionales para garantizar la llegada de suministros de ayuda.",
    },
    item13: {
        title: "A medida que continúa la guerra palestino-israelí, el número de víctimas sigue aumentando. El rescate de las víctimas es inminente y el Knight Consortium organiza activamente equipos de rescate para brindar un rescate inmediato.",
    },
    item14: {
        title: "En julio de 2023, afectado por el tifón número 5 \"Dusuri\", se produjeron lluvias extremadamente intensas en Beijing, Tianjin, Hebei y otros lugares de China, que provocaron inundaciones. El Consorcio Knight lanzó un rescate de emergencia y proporcionó canales de rescate oportunos para ayudar a los ancianos y a las personas en peligro a salir de los problemas.",
    },
    item15: {
        title: 'La Fundación Knight acudió inmediatamente al rescate y proporcionó un rayo de ayuda a las víctimas de la guerra palestino-israelí. Frente a los desafíos de la guerra palestino-israelí, nuestra Fundación de Caballeros apoya firmemente a las víctimas. Como equipo comprometido con la equidad financiera y la responsabilidad social, siempre hemos considerado el cuidado y la asistencia como una de nuestras responsabilidades.',
    },
    item16: {
        title: 'En la guerra palestino-israelí, somos muy conscientes de los desafíos y dificultades que enfrentan las víctimas. Hemos tomado medidas de emergencia para proporcionar asistencia material que se necesita con urgencia a las zonas afectadas. Estos suministros incluyen alimentos, agua, suministros médicos y equipo de rescate de emergencia y están diseñados para brindar ayuda y apoyo muy necesarios a los afectados por el desastre.',
    },
    item17: {
        title: 'La Knights Foundation lleva mucho tiempo comprometida con apoyar la revitalización de la posguerra. Reconstruir comunidades y ayudar a los afectados es crucial. Por lo tanto, no dudamos en tender la mano para garantizar que los afectados por la guerra puedan reconstruir sus vidas.',
    },
    item18: {
        title: 'El Grupo Knight siempre ha sido conocido por su asistencia profesional. Contamos con un equipo experimentado que puede responder rápidamente y brindar soluciones prácticas. Todos operamos de manera eficiente y transparente para garantizar que toda la ayuda se utilice de manera adecuada y efectiva.',
    },
    item19: {
        title: 'Elegir la asistencia profesional de Knight Consortium significa que ha elegido un socio confiable, un equipo dedicado a mejorar vidas y reconstruir la esperanza. No escatimaremos esfuerzos para brindar a las víctimas el apoyo que necesitan para ayudarlas a superar las dificultades y avanzar hacia un futuro mejor.',
    },
    item20: {
        title: 'En medio de la bruma de la guerra, la reconstrucción de posguerra es un paso fundamental que debemos dar. La reconstrucción de comunidades, la reparación de edificios dañados y la reintegración de las víctimas a la sociedad requieren asistencia profesional y apoyo comprometido.',
    },
    item21: {
      title: 'En medio de la bruma de la guerra, unimos nuestras manos para brindar ayuda a las víctimas. La Knights Foundation siempre ha defendido un sentido de responsabilidad social y está comprometida a ayudar a los afectados por la guerra. ¡Iluminemos las vidas de las víctimas de la guerra con la luz de la esperanza!',
    },
    item22: {
      title: 'En los momentos difíciles después de la guerra, el Consorcio de Caballeros está dispuesto a ser su socio confiable y unirse en la misión de rescate y reconstrucción. Sabemos que cada víctima de un desastre necesita atención y asistencia, por eso participamos activamente en diversas operaciones de socorro para brindar el apoyo necesario a quienes sufren.',
    },
    item23: {
      title: 'La Fundación Knights se ha comprometido a difundir este espíritu. A través de acciones caritativas, proyectos de ayuda y trabajo voluntario, nos esforzamos por mejorar vidas, apoyar a las comunidades y hacer del mundo un lugar más cálido. Sólo trabajando juntos podremos crear un futuro mejor y más esperanzador.',
    },
    item24: {
        title: 'Argentina fue azotada por una tormenta súper fuerte, que provocó la pérdida de 16 vidas inocentes. El Consorcio Knight se entristeció profundamente y respondió de inmediato, y nuestro equipo profesional participó de inmediato en el trabajo de socorro en caso de desastre. Durante esta emergencia, seguimos comprometidos a apoyar a las áreas afectadas.',
    },
    item25: {
        title: 'Nos enteramos de que se produjo un terremoto de magnitud 6,2 en Gansu, China. Este desastre causó gran angustia a los residentes locales. En este momento difícil, la Fundación Knights está del lado de las personas en la zona del desastre y les extiende su más sentido pésame y apoyo.',
    },
    item26: {
        title: 'Hay momentos en el viaje de la vida que requieren más cuidado y apoyo. La Fundación Knight asume la responsabilidad social como su propia responsabilidad. Creemos firmemente que ayudar a los necesitados es una parte importante para construir una sociedad mejor y siempre estaremos a su lado.',
    },
    item27: {
        title: '¡El Knight Consortium está a punto de partir de nuevo! Al ver la leche, el aceite, los fideos, las galletas y otros alimentos cuidadosamente dispuestos en el suelo, estaban a punto de entregárselos a una familia local necesitada. Al ver sus caras felices, sabemos cuánta calidez y esperanza traerán estos suministros. Un amor, una calidez, la Fundación Knight interpreta la responsabilidad social con acciones, esperemos con ansias el toque que trae esta entrega.',
    },
    item28: {
        title: 'Recientemente, hemos sido testigos de un gran equipo de miembros del consorcio de caballeros, llenos de energía y pasión, llevando cargas de alimentos a las comunidades locales para visitar a mujeres, ancianos y niños. Cada miembro de este equipo irradia entusiasmo y atención ilimitados, y su presencia inyecta una corriente cálida en la comunidad. Durante el evento vimos a hombres y mujeres jóvenes interactuando cordialmente con ancianos y niños, sus sonrisas estaban llenas de alegría y cariño. Ya sea distribuyendo comida o charlando, todos participan y brindan calidez y atención a los demás con corazón. Este equipo dinámico de Knight Financial Group demuestra el espíritu de unidad y amistad e inyecta un fuerte amor a la comunidad.',
    },
    item29: {
        title: 'Recientemente, un grupo de miembros del Consorcio Knight realizó una conmovedora visita. Fueron al área local y visitaron a mujeres, ancianos y niños, brindándoles calidez y atención. Este evento no es sólo una simple visita, sino también un reflejo de sincero cuidado y apoyo a la comunidad. Durante el evento, los miembros de la Fundación Knights sostuvieron conversaciones cordiales con los entrevistados y compartieron historias y experiencias de cada uno. Los ancianos sonreían, los niños reían y las mujeres estaban agradecidas. Escucharon atentamente y acompañaron con amor, haciendo que cada entrevistado sintiera la calidez y el cariño de la comunidad.',
    },
    item30: {
        title: 'En un día soleado, el equipo de San Juan del Consorcio Knight visitó con total atención a familias locales necesitadas. Los miembros del equipo distribuyeron artículos de primera necesidad como arroz, aceite de cocina, fideos huecos, latas, pan y galletas, y llevaron a cabo cordiales condolencias y conversaciones con cada familia. La distribución material de este tiempo no es sólo un apoyo práctico para las necesidades diarias, sino también un consuelo espiritual para cada familia. Los voluntarios de Knight comparten momentos cálidos con las familias que reciben ayuda, transmitiéndose cariño y esperanza unos a otros. Las familias ayudadas expresaron su agradecimiento a los voluntarios Caballeros, esta calidez los llenó de confianza y expectativas para el futuro. Cuando los voluntarios se fueron, las familias enviaron sus más sinceras bendiciones y agradecieron al equipo de San Juan de la Fundación Caballeros por el cuidado y calidez que les brindaron.',
    },
    item31: {
        title: '¡Los miembros de la Fundación Knights extendieron una mano amiga a varias familias pobres locales y les enviaron abundantes artículos de primera necesidad! En un evento anterior, entusiastas voluntarios de la Fundación Knights fueron al área local llevando abundantes suministros como aceite de cocina, fideos, trigo y harina, y entregaron estos artículos de primera necesidad a varias familias pobres. Esta no es sólo una simple donación, sino también un sincero pésame y una comunicación. Al recibir esta calidez, las familias ayudadas expresaron su profunda gratitud a los voluntarios de los Caballeros. Las dificultades de la vida parecen volverse más cálidas y llenas de esperanza y expectativa gracias a este cuidado.',
    },
    item32: {
        title: 'Los voluntarios de la Fundación Knight se embarcaron en un conmovedor viaje benéfico con artículos de primera necesidad como pan, aceite, leche, azúcar, etc. Se adentraron en las zonas montañosas y brindaron ayuda tangible a las familias necesitadas que vivían en las laderas y tenían transporte limitado. Esta distribución de material cubre todos los aspectos de la vida diaria familiar, incluido el aceite de cocina, la harina, la leche, el azúcar, etc., asegurando que cada familia pueda sentir el cálido cuidado de la Fundación Knights. Los voluntarios no sólo son portadores de suministros, sino también de compañía, condolencias y comunicación. Las familias que reciben ayuda están ubicadas en zonas montañosas remotas y a menudo enfrentan desafíos como transporte inconveniente y condiciones de vida difíciles. Por lo tanto, la llegada de los Caballeros voluntarios fue una bendición oportuna para ellos y los llenó de gratitud. Todos compartieron las historias de los demás durante la conversación, lo que hizo que este evento benéfico fuera más cálido y significativo.',
    },
    item33: {
      title: 'Recientemente, el sol brillaba intensamente y los miembros del Consorcio Knight llegaron al área local con cubos de aceite de cocina y jugo para echar una mano y enviar calidez y atención a quienes necesitaban apoyo y ayuda. En este hermoso día, no sólo somos miembros del Consorcio de Caballeros, sino también parte de la comunidad. Sabemos que, como equipo, nuestras responsabilidades y misiones están sobre nuestros hombros. Por lo tanto, estamos dispuestos a trabajar duro y utilizar acciones para demostrar nuestro cuidado y apoyo a la comunidad. Difundamos nuestro amor juntos y llenemos el mundo de calidez y esperanza. ¡Gracias por su apoyo, sigamos avanzando juntos y contribuyendo al desarrollo y progreso de la comunidad!',
    },
    item34: {
      title: 'Recientemente, nuestro equipo del Consorcio Knight llegó a Córdoba, Argentina. Este hermoso lugar está lleno de calidez y esperanza. Nuestro equipo de casi 30 miembros está reunido aquí. Con un corazón cálido y su amor y apoyo, ayudaremos a más personas necesitadas y les brindaremos calidez y atención. En esta acción llena de amor, no sólo somos miembros del Consorcio de Caballeros, sino también su portavoz y mensajero. Gracias a su generosidad y amor, podemos llegar hasta aquí y llevar gratitud a más personas. Unamos nuestras manos para contribuir a la sociedad y llenar el mundo de más calidez y esperanza. ¡Gracias por su apoyo, sigamos difundiendo el poder del amor!',
    },
    item35: {
      title: 'Membros do consórcio Knights se reuniram offline para compartilhar a festa das finais da Copa América e da Copa Europeia! Não apenas saboreamos uma comida deliciosa à mesa, mas também compartilhamos nosso entusiasmo e expectativas pelo jogo em meio a risadas e risadas. Não importa para qual time torcemos, neste momento somos todos torcedores fiéis do futebol, torcendo pelo campeão em nossos corações. As finais da Taça América e da Taça dos Campeões Europeus não são apenas uma festa do futebol, mas também um testemunho da unidade e amizade dos membros do Consórcio dos Cavaleiros.',
    },
    item36: {
      title: 'Reunimo-nos neste momento especial não só para celebrar o encanto do futebol, mas também para fortalecer as ligações e a cooperação mútuas. Cada jogo é um esforço de equipe e cada gol é nossa alegria coletiva! Obrigado a todos os membros do Consórcio dos Cavaleiros que participaram deste jantar. Seu entusiasmo e energia acrescentaram uma emoção sem fim a esta noite. Vamos continuar avançando de mãos dadas e receber mais vitórias e glórias juntos!',
    },
}

export const industry = {
    title: "Industria de inversión",
    cat1: {
        name: "finance",
        item1: {
            title: "El equipo de inversión de KNight Consortium compró acciones de varias empresas mineras de metales preciosos. Estas empresas tuvieron un buen desempeño durante 2012, ya que el aumento de los precios de los metales preciosos hizo que los precios de sus acciones subieran, lo que resultó en enormes ganancias para el sindicato KNights.",
        },
        item2: {
            title: "Durante el período de enero a febrero de 2012, el precio internacional del oro fluctuó. Los analistas financieros de KNight Consortium aprovecharon la oportunidad para comprar a precios bajos y obtuvieron casi un 60% de ganancias en sólo tres meses.",
        }
    },
    cat2: {
        name: "medical",
        item1: {
            title: "En 2020, se estableció el Centro de servicios médicos del KNight Consortium y el método de tratamiento de \"inmunoterapia tumoral\" desarrollado conjuntamente con Merck Pharmaceutical Group tuvo éxito.",
        },
        item2: {
            title: "KNight Consortium Medical Services Center y Novartis Pharmaceuticals han desarrollado el fármaco dirigido contra el cáncer Houng-592",
        }
    },
    cat3: {
        name: "metaverse",
        item1: {
            title: "En 2021, se creó el centro web3.0 de KNight Consortium para crear una plataforma social descentralizada, que atrajo una gran atención y fue bien recibida por los usuarios.",
        },
        item2: {
            title: "En el mercado alcista de las criptomonedas en enero de 2017, el Consorcio KNight aprovechó la oportunidad y llevó a los miembros de la fundación a lograr altas ganancias, y los activos de todos los miembros se duplicaron rápidamente.",
        }
    }
}


export const about = {
    title: "KNight Consortium une el poder de los inversores minoristas para hacer realidad el sueño de la libertad financiera.",
    desc: "Los caballeros aman la fortuna, como es debido. ¡El Consorcio KNight está dispuesto a guiar este amplio camino para que más personas lo descubran, para que todos puedan lograr prosperidad y prosperidad inicial, y luego lograr la autorrealización! Ayude a las inversiones individuales a escapar del destino de ser cosechadas por instituciones y obtener ganancias a través de operaciones de capital profesionales y confiables, ayudando así a los miembros del equipo a hacer realidad sus sueños de libertad financiera.",
    service: {
        title: "Nuestros objetivos de capital sindical",
        item1: {
            title: "",
            desc: "Hacer todo lo posible para promover el desarrollo económico y social global y las relaciones económicas.<br><br><br><br><br><br><br>"
        },
        item2: {
            title: "",
            desc: "Promover un mayor desarrollo de la caridad para mejorar los medios de vida de las personas, brindar asistencia nacional a los refugiados internacionales que sufren desastres naturales y utilizar acciones prácticas para ayudar a los grupos desfavorecidos a salir de los problemas y permitirles vivir una vida feliz y pacífica."
        },
        item3: {
            title: "",
            desc: "Establecer equipos de servicio en el extranjero para ayudar al desarrollo de la agricultura, la industria, la construcción económica, la atención médica, la educación, etc. a nivel mundial.<br><br><br><br>"
        }
    },
    timelinedata: {
        item1: {
            title: "2023-2024",
            desc: "La atención se centra en América del Sur como área clave para la asistencia y ayuda de la Fundación KNight Capital, y se espera que invierta al menos 50 millones de dólares en asistencia privada. Dentro de tres años, se establecerán 1.000 oficinas benéficas fuera de línea de la Fundación KNight Capital y 5.000 oportunidades de empleo en América del Sur."
        },
        item2: {
            title: "2025",
            desc: "Los ingresos por inversiones y el monto de las donaciones de la Fundación KNight Capital alcanzan los 100 millones de dólares anuales."
        },
        item3: {
            title: "2026",
            desc: "Lograr 5.000 oficinas benéficas fuera de línea de la Fundación KNight Capital en todo el mundo y más de 20.000 oportunidades de empleo."
        },
        item4: {
            title: "2027",
            desc: "Se espera que los ingresos por inversiones y el monto de las donaciones de la Fundación KNight Capital alcancen más de 300 millones de dólares estadounidenses. A través del apoyo a la tecnología agrícola y la construcción de infraestructura, ayudará a más de 200 millones de personas en todo el mundo a escapar del umbral de pobreza internacional, permitiéndoles obtener salud, felicidad y alegría."
        }
    },
    counterdata: {
      title: "Estado actual y planes futuros de KNight Consortium",
      desc: "",
      item1: {
          title: 'Fondos',
          unit: "mil millones",
      },
      item2: {
          title: 'Usuarios',
          unit: "mil millones",
      },
      item3: {
          title: 'Invertir',
          unit: "Pareja",
      },
      item4: {
          title: 'Donar',
          unit: "mil millones",
      }
    },
    teamdata: {
        title: "Miembros",
        item1: {
            name: "Remus John Worm",
            desc: "Maestría en Economía, Facultad de Economía de la Universidad de Chicago"
        },
        item2: {
            name: "Li Rongcheng",
            desc: "Maestría en Ciencias y Maestría en Administración de Empresas, Universidad de Ottawa"
        },
        item3: {
            name: "Liu Caijun",
            desc: "Graduado de la Universidad de Pekín, Maestría en Gestión"
        }
    },
    article: {
        title: "Al reunir el poder de los inversores minoristas, Knight Consortium se compromete a confrontar a las instituciones y luchar por más derechos para los inversores comunes.",
        desc: "En el mercado de valores existen dos tipos de actores, uno son los inversores minoristas (individuos) y el otro son los inversores institucionales (casas de apuestas). El poder financiero institucional siempre ha estado exprimiendo el espacio vital de los inversores minoristas, monopolizando y manipulando el mercado de valores. La mayoría de los inversores minoristas se han sentido agraviados por esto durante mucho tiempo y comenzaron una batalla épica entre los inversores minoristas y los vendedores en corto de Wall Street: el incidente del \"GameStop Bloodbath on Wall Street\". son la fuerza más poderosa y tienen un dominio incuestionable sobre la tendencia del precio de las acciones."
    },
    certdata: {
        title:"Certificados de fundaciones nacionales",
        item1: {
            title: "",
            desc: "EE.UU",
        },
        item2: {
            title: "",
            desc: "Singapur",
        },
        item3: {
            title: "",
            desc: "REINO UNIDO.",
        },
    },
    purpose: {
        title:"Misión de la Fundación Caballeros",
        btntitle:"Descargar PDF",
        image: require('@/assets/images/pdf/pdf_es.png'),
        downlink:"/es.pdf",
    }
}


export const digitalFour = {
    digita: {
        title:"Actividades benéficas de la Fundación Knights."
    }
};


export const BlogDinner = {
    dinnerGrid: {
        title:"Los miembros del consorcio Knight tienen una cena fuera de línea"
    }
};


export const homeNotice = {
    notice:{
        title:"Anuncio especial del consorcio de los Caballeros",
        desc:""
    }
}