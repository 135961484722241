<template>
    <Layout header-class="header-left-align" nav-alignment="left" :header-transparency="true" :show-top-header="false" buy-button-class="btn-default btn-small">

        <!-- Start Elements Area -->
        <div class="rwt-team-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 mb--15">
                        <SectionTitle
                            text-align="center"
                            subtitle=""
                            :title="$t('team.leader1.title')"
                            data-aos="fade-up"
                            data-aos-delay="60"
                        />
                    </div>
                </div>
                <div class="row row--45">
                    <div class="col-lg-12 col-md-6 col-12 mt--30"
                         v-for="(teamMember, index) in teamData5"
                         :key="index">
                         <Team :team-member="teamMember" :team-style="3" />
                        <!--  -->
                    </div>
                </div>
            </div>
        </div>

        <!-- End Elements Area -->

        <!-- Start Elements Area -->
        <div class="rwt-team-area rn-section-gap">
            <div class="wrapper plr--200 plr_sm--15">
                <div class="row mb--20">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle=""
                            :title="$t('team.leader2.title')"
                            data-aos="fade-up"
                            data-aos-delay="60"
                        />
                    </div>
                </div>
                <div class="row row--45">
                    <div class="col-lg-6 col-xl-4 col-md-6 col-12 mt--30"
                         v-for="(teamMember, index) in teamData3"
                         :key="index">
                        <Team :team-member="teamMember" :team-style="3"/>
                        <!-- :team-style="4" -->
                    </div>
                </div>
            </div>
        </div>

        <Separator />

        <!-- Start Elements Area -->
        <div class="rwt-team-area rn-section-gap">
            <div class="wrapper plr--200 plr_sm--15">
                <div class="row mb--20">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle=""
                            :title="$t('team.leader3.title')"
                            data-aos="fade-up"
                            data-aos-delay="60"
                        />
                    </div>
                </div>
                <div class="row row--45">
                    <div class="col-lg-6 col-xl-4 col-md-6 col-12 mt--30"
                         v-for="(teamMember, index) in teamData4"
                         :key="index">
                        <Team :team-member="teamMember" :team-style="4"/>
                        
                        <!-- :team-style="4" -->
                    </div>
                </div>
            </div>
        </div>

        <Separator />

        <!-- Start Elements Area -->
        <div class="rwt-team-area rn-section-gap">
            <div class="wrapper plr--200 plr_sm--15">
                <div class="row mb--20">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle=""
                            :title="$t('team.leader4.title')"
                            data-aos="fade-up"
                            data-aos-delay="60"
                        />
                    </div>
                </div>
                <div class="row row--45">
                    <div class="col-lg-4 col-xl-4 col-md-4 col-12 mt--30"
                         v-for="(teamMember, index) in teamData2"
                         :key="index">
                        <Team :team-member="teamMember" :team-style="2"/>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Elements Area -->

        <Separator/>

        <!-- Start Elements Area -->
        <div class="rwt-team-area rn-section-gap">
            <div class="wrapper plr--200 plr_sm--15">
                <div class="row mb--20">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle=""
                            :title="$t('team.leader5.title')"
                            data-aos="fade-up"
                            data-aos-delay="60"
                        />
                    </div>
                </div>
                <div class="row row--45">
                    <div class="col-lg-6 col-xl-4 col-md-6 col-12 mt--30"
                         v-for="(teamMember, index) in teamData"
                         :key="index">
                        <Team :team-member="teamMember" :team-style="5"/>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- End Elements Area -->
    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import Breadcrumb from '../components/elements/breadcrumb/Breadcrumb'
    import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
    import Team from '../components/elements/team/Team'
    import Separator from '../components/elements/separator/Separator'

    export default {
        name: 'TeamPage',
        components: {Separator, Team, SectionTitle, Layout, Breadcrumb},
        data() {
            return {
                teamData: [
                    {
                        image: 'team-01',
                        name: this.$t('team.leader5.item1.name'),
                        designation: this.$t('team.leader5.item1.desc'),
                        location: '',
                        description: 'Yes, I am a product designer. I have a passion for product design.',
                        socialNetwork: [
                            {
                                icon: 'facebook',
                                url: '#'
                            },
                            {
                                icon: 'twitter',
                                url: '#'
                            },
                            {
                                icon: 'instagram',
                                url: '#'
                            },
                        ]
                    },
                    {
                        image: 'team-02',
                        name: this.$t('team.leader5.item2.name'),
                        designation: this.$t('team.leader5.item2.desc'),
                        location: '',
                        description: 'Yes, I am a product designer. I have a passion for product design.',
                        socialNetwork: [
                            {
                                icon: 'facebook',
                                url: '#'
                            },
                            {
                                icon: 'twitter',
                                url: '#'
                            },
                            {
                                icon: 'instagram',
                                url: '#'
                            },
                        ]
                    },
                    {
                        image: 'team-03',
                        name: this.$t('team.leader5.item3.name'),
                        designation: this.$t('team.leader5.item3.desc'),
                        location: '',
                        description: 'Yes, I am a product designer. I have a passion for product design.',
                        socialNetwork: [
                            {
                                icon: 'facebook',
                                url: '#'
                            },
                            {
                                icon: 'twitter',
                                url: '#'
                            },
                            {
                                icon: 'instagram',
                                url: '#'
                            },
                        ]
                    },
                ],
                teamData2: [
                    {
                        image: 'team-08',
                        name: this.$t('team.leader4.item1.name'),
                        designation: this.$t('team.leader4.item1.desc')
                    },
                    {
                        image: 'team-09',
                        name: this.$t('team.leader4.item2.name'),
                        designation: this.$t('team.leader4.item2.desc')
                    },
                    {
                        image: 'team-10',
                        name: this.$t('team.leader4.item3.name'),
                        designation: this.$t('team.leader4.item3.desc')
                    },
                ],
                teamData3: [
                    {
                        image: 'team-12',
                        name: this.$t('team.leader2.item1.name'),
                        designation: this.$t('team.leader2.item1.desc'),
                    },
                    {
                        image: 'team-13',
                        name: this.$t('team.leader2.item2.name'),
                        designation: this.$t('team.leader2.item2.desc'),
                    },
                    {
                        image: 'team-07',
                        name: this.$t('team.leader2.item3.name'),
                        designation: this.$t('team.leader2.item3.desc'),
                    }
                ],
                teamData4: [
                    {
                        image: 'team-dark-01',
                        name: this.$t('team.leader3.item1.name'),
                        designation: this.$t('team.leader3.item1.desc'),
                        location: '',
                        description: 'Yes, I am a product designer. I have a passion for product design.'
                    },
                    {
                        image: 'team-dark-02',
                        name: this.$t('team.leader3.item2.name'),
                        designation: this.$t('team.leader3.item2.desc'),
                        location: '',
                        description: 'Yes, I am a product designer. I have a passion for product design.'
                    },
                    {
                        image: 'team-dark-03',
                        name: this.$t('team.leader3.item3.name'),
                        designation: this.$t('team.leader3.item3.desc'),
                        location: '',
                        description: 'Yes, I am a product designer. I have a passion for product design.'
                    }
                ],
                teamData5: [
                    {
                        image: 'team-11',
                        name: this.$t('team.leader1.name'),
                        designation: this.$t('team.leader1.desc'),
                    },
                ],
            }
        }
    }
</script>