import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap/scss/bootstrap.scss'
import 'animate.css'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import './assets/scss/style.scss'
import 'bootstrap'
import CoolLightBox from 'vue-cool-lightbox'
import VueScrollActive from 'vue-scrollactive'
import AOS from 'aos'
import 'aos/dist/aos.css'
import VueTypedJs from 'vue-typed-js'

Vue.config.productionTip = false
Vue.use(CoolLightBox)
Vue.use(VueScrollActive)
Vue.use(VueTypedJs)

import VueI18n from "vue-i18n";
Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: localStorage.getItem("lang") || "en", // 语言标识
  //this.$i18n.locale // 通过切换locale的值来实现语言切换
  messages: {
    en: require("./language/en"), // 英文
    // ar: require("./language/ar"), // 越南语
    zh: require("./language/zh"), // 中文
    tw: require("./language/tw"), // 中文繁体
    de: require("./language/de"), // 德语
    pt: require("./language/pt"), // 葡萄牙
    fr: require("./language/fr"), // 法语
    ar: require("./language/ar"), // 阿拉伯
    es: require("./language/es"), // 西班牙
  }
});

new Vue({
  i18n,
  router,
  created() {
    AOS.init({
      duration: 800,
      once: true,
    })
  },
  render: (h) => h(App),
}).$mount('#app')
