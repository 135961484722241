<template>
    <Layout header-class="header-left-align" nav-alignment="left" :header-transparency="true" :show-top-header="false" buy-button-class="btn-default btn-small">
        <!-- Start Slider Area -->
        <div class="slider-area slider-style-1 height-850 bg_image"
             
             :style="{'background-image': `url(${require(`@/assets/images/bg/bg-image-12.jpg`)})`}">
            <!-- <div class="container">data-black-overlay="7"
                <div class="row">
                    <div class="col-lg-12">
                        <div class="inner pt--80 text-center">
                            <div>
                                <h1 class="rn-sub-badge" data-aos="fade-up" data-aos-delay="100">
                                    <span class="theme-gradient">{{$t('about.title')}}</span>
                                </h1>
                            </div>
                            <h1 class="title display-one" data-aos="fade-up" data-aos-delay="150">
                                {{$t('about.desc')}}
                            </h1>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
        <!-- End Slider Area -->


        <!-- 证书 -->
        <div class="rn-service-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle=""
                            :title="$t('about.certdata.title')"
                            description=""
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <ServiceCertOne
                    service-style="service__style--1 bg-color-blackest radius mt--25"
                    icon-size="62"
                    text-align="center"
                />
            </div>
        </div>



        <!-- Start Service Area -->
        <div class="service-area rn-section-gapTop">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="content" data-aos="fade-up">
                            <h3 class="title">{{$t('about.title')}}</h3>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <p class="mb--10" data-aos="fade-up">
                            {{$t('about.desc')}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Service Area -->

        <!-- Start Brand Area -->
        <!-- <div class="rwt-brand-area pb--60 pt--30">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 mt--10">
                        <Brand :brand-list="brandList" :brand-style="3"/>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- End Brand Area -->

        <Separator/>


        <!-- PDF 下载 -->
        <div class="rn-service-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle=""
                            :title="$t('about.purpose.title')"
                            description=""
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <ServicePurpose
                    service-style="service__style--1 bg-color-blackest radius mt--25"
                    icon-size="62"
                    text-align="center"
                />
            </div>
        </div>


        <!-- Start Service Area -->
        <div class="rn-service-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle=""
                            :title="$t('about.service.title')"
                            description=""
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <ServiceOne
                    service-style="service__style--1 bg-color-blackest radius mt--25"
                    icon-size="62"
                    text-align="center"
                />
            </div>
        </div>
        <!-- End Service Area -->

        <div class="rwt-timeline-area rn-section-gapBottom">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <Timeline :timeline-data="timelineData" :timeline-style="3"/>
                    </div>
                </div>
            </div>
        </div>

        <Separator/>

        <AboutFour :image="require(`@/assets/images/about/contact-image.jpg`)"/>

        <!-- Start Elements Area -->
        <div class="rwt-counterup-area pb--100">
            <div class="container mt_dec--30">
                <Counter
                    :counter-style="5"
                    text-align="center"
                    :counter-data="counterData"
                    column="col-lg-3 col-md-6 col-sm-6 col-12"
                />
            </div>
        </div>
        <!-- End Elements Area -->

        <Separator/>

        <!-- Start Elements Area -->
        <!-- <div class="rwt-team-area rn-section-gap">
            <div class="container">
                <div class="row mb--20">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle=""
                            :title="$t('about.teamdata.title')"
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <div class="row row--45">
                    <div class="col-lg-6 col-xl-4 col-md-6 col-12 mt--30"
                         v-for="(teamMember, index) in teamData"
                         :key="index">
                        <Team :team-member="teamMember" :team-style="4"/>
                    </div>
                </div>
            </div>
        </div> -->

        <div class="service-area rn-section-gapTop">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="content" data-aos="fade-up">
                            <h3 class="title">{{$t('about.article.title')}}</h3>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <p class="mb--10" data-aos="fade-up">
                            {{$t('about.article.desc')}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Elements Area -->
    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import Separator from '../components/elements/separator/Separator'
    import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
    import AboutFour from '../components/elements/about/AboutFour'
    import ServiceOne from '../components/elements/service/ServiceOne'
    import ServiceCertOne from '../components/elements/service/ServiceCertOne.vue'
    import ServicePurpose from '../components/elements/service/ServicePurpose.vue'
    import Timeline from '../components/elements/timeline/Timeline'
    import Counter from '../components/elements/counterUp/Counter'
    import Brand from '../components/elements/brand/Brand'
    import Team from '../components/elements/team/Team'

    export default {
        name: 'About',
        components: {Team, Brand, Counter, Timeline, ServiceOne, ServiceCertOne, ServicePurpose, AboutFour, SectionTitle, Separator, Layout},
        data() {
            return {
                brandList: [
                    {
                        image: require(`@/assets/images/brand/brand-01.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-02.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-03.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-04.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-05.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-06.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-07.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-08.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-01.png`)
                    },

                ],
                timelineData: [
                    {
                        id: '1',
                        title: this.$t('about.timelinedata.item1.title'),
                        description: this.$t('about.timelinedata.item1.desc'),
                    },
                    {
                        id: '2',
                        title: this.$t('about.timelinedata.item2.title'),
                        description: this.$t('about.timelinedata.item2.desc'),
                    },
                    {
                        id: '3',
                        title: this.$t('about.timelinedata.item3.title'),
                        description: this.$t('about.timelinedata.item3.desc'),
                    },
                    {
                        id: '4',
                        title: this.$t('about.timelinedata.item4.title'),
                        description: this.$t('about.timelinedata.item4.desc'),
                    },
                ],
                counterData: [
                    {
                        number: 10,
                        title: this.$t('home.counterdata.item1.title'),
                        unit: this.$t('home.counterdata.item1.unit'),
                        decimals: 0
                    },
                    {
                        number: 2.4,
                        title: this.$t('home.counterdata.item2.title'),
                        unit: this.$t('home.counterdata.item2.unit'),
                        decimals: 2
                    },
                    {
                        number: 500,
                        title: this.$t('home.counterdata.item3.title'),
                        unit: this.$t('home.counterdata.item3.unit'),
                        decimals: 0
                    },
                    {
                        number: 3,
                        title: this.$t('home.counterdata.item4.title'),
                        unit: this.$t('home.counterdata.item4.unit'),
                        decimals: 0
                    }
                ],
                teamData: [
                    {
                        image: 'team-12',
                        name: this.$t('about.teamdata.item1.name'),
                        designation: this.$t('about.teamdata.item1.desc'),
                    },
                    {
                        image: 'team-13',
                        name: this.$t('about.teamdata.item2.name'),
                        designation: this.$t('about.teamdata.item2.desc'),
                    },
                    {
                        image: 'team-07',
                        name: this.$t('about.teamdata.item3.name'),
                        designation: this.$t('about.teamdata.item3.desc'),
                    }
                ]
            }
        }
    }
</script>